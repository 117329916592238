import moment from 'moment';
import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { GetSpotStatsQuery } from '@graphql';
import {
  ADDICTS_COLOR,
  ADDICTS_BACKGROUND_COLOR,
  BOOKINGS_COLOR,
  BOOKINGS_BACKGROUND_COLOR,
  EVENTS_COLOR,
  EVENTS_BACKGROUND_COLOR,
} from '../constants';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface AccountDoughnutProps {
  period: 'day' | 'week' | 'month' | 'quarter' | 'year' | 'always';
  stats: GetSpotStatsQuery['getSpotStats'] | null;
}

export default function CountLineChart(props: AccountDoughnutProps) {
  const { period, stats } = props;

  const day = Array.from({ length: 8 }, (_, i) =>
    moment()
      .hour(i * 3)
      .format('HH')
  );
  const week = [1, 2, 3, 4, 5, 6, 0].map((w) => moment().day(w).format('ddd'));
  const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((m) => moment().month(m).format('MMM'));
  const quarter = ['Q1', 'Q2', 'Q3', 'Q4'];
  const year = Array.from({ length: 6 }, (_, i) => (new Date().getFullYear() - i).toString()).reverse();
  const always = ['2020', '2021', '2022', '2023', '2024', '2025', '2026'];
  const labels = { day, week, month, quarter, year, always };

  const data = {
    labels: labels[period],
    datasets: [
      {
        label: 'Addicts',
        data: stats?.addicts.dataSet ?? [],
        borderColor: ADDICTS_COLOR,
        backgroundColor: ADDICTS_BACKGROUND_COLOR,
        borderWidth: 2,
        tension: 0.3,
      },
      {
        label: 'Bookings',
        data: stats?.bookings.dataSet ?? [],
        borderColor: BOOKINGS_COLOR,
        backgroundColor: BOOKINGS_BACKGROUND_COLOR,
        borderWidth: 2,
        tension: 0.3,
      },
      {
        label: 'Events',
        data: stats?.events.dataSet ?? [],
        borderColor: EVENTS_COLOR,
        backgroundColor: EVENTS_BACKGROUND_COLOR,
        borderWidth: 2,
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Gains par jour',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            return ` ${context.raw > 0 ? '+' : ''}${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: 'Temps',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)', // Couleur des lignes de fond
        },
      },
      y: {
        title: {
          display: false,
          text: 'Volumes',
        },
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.2)', // Couleur des lignes de fond
        },
      },
    },
    ticks: {
      stepSize: 1, // 🔥 Forcer des valeurs entières uniquement
      callback: function (value: any) {
        return Number.isInteger(value) ? value : null; // 🔥 Masquer les décimales
      },
    },
  };

  return <Line data={data} options={options} />;
}

import { ListHomeEventsQuery, ListHomeSpotsQuery } from '@graphql';
import { SlidersWrapper } from '@components';
import Slider from './Slider';

interface SlidersProps {
  categories: ListHomeEventsQuery['eventCategories'] | ListHomeSpotsQuery['homeSpots'];
}

export const Sliders = (props: SlidersProps) => {
  const { categories } = props;

  return (
    <SlidersWrapper>
      {categories.map((category, index) => (
        <Slider key={category.id} category={category} />
      ))}
    </SlidersWrapper>
  );
};

import * as React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import { useListHomeSpotsLazyQuery, ListHomeSpotsQuery } from '@graphql';
import { EVENTS_SPOTS_CACHE_TTL, SPOTS_LAST_CALL } from '@utils';
import { Sliders, Highlight } from '@components';

const SpotsPage = () => {
  const [homeSpots, setHomeSpots] = React.useState<ListHomeSpotsQuery['homeSpots'] | null>(null);
  const { t } = useTranslation('spot');

  const getLastCallFromStorage = () => {
    const storedLastCall = localStorage.getItem(SPOTS_LAST_CALL);
    return storedLastCall ? parseInt(storedLastCall, 10) : new Date().getTime();
  };
  const [lastCall, setLastCall] = React.useState<number>(getLastCallFromStorage());

  const [getSpots] = useListHomeSpotsLazyQuery({
    onCompleted: ({ homeSpots }) => {
      setHomeSpots(homeSpots ?? null);
      const now = new Date().getTime();
      setLastCall(now);
      localStorage.setItem(SPOTS_LAST_CALL, now.toString());
    },
  });

  React.useEffect(() => {
    const now = new Date().getTime();
    const timeSinceLastCall = now - lastCall;

    if (!homeSpots || timeSinceLastCall > EVENTS_SPOTS_CACHE_TTL) {
      getSpots({
        fetchPolicy: timeSinceLastCall > EVENTS_SPOTS_CACHE_TTL ? 'network-only' : 'cache-first',
      });
    }
  }, [homeSpots, lastCall]);

  if (!homeSpots) {
    return null;
  }

  return (
    <Stack pb={8}>
      <Highlight type="spot" />
      <Sliders
        categories={homeSpots.reduce(
          (categories, category) => {
            if (category.spots.length) {
              categories.push({ ...category, title: t(`homeCategories.${category.title}`) });
            }

            return categories;
          },
          [] as ListHomeSpotsQuery['homeSpots']
        )}
      />
    </Stack>
  );
};

export default SpotsPage;

import { useTheme, alpha, lighten } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import * as React from 'react';
import * as Device from 'react-device-detect';

import StatsLineChart from './StatsLineChart';

const SPACING = 2;

interface StatsSlotProps {
  status?: 'active' | 'disabled';
}

export function StatsSlot(props: StatsSlotProps) {
  const { status = 'active' } = props;
  const [dateRange, setDateRange] = React.useState<{ from: Date; to: Date } | null>(null);
  const [counts, setCounts] = React.useState(null);
  // const [counts, setCounts] = React.useState<GetAccountCountsQuery['accountCounts'] | null>(null);
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [tab, setTab] = React.useState(0);
  const theme = useTheme();

  const thisDayDateRante = {
    from: moment().startOf('day').toDate(),
    to: moment().endOf('day').toDate(),
  };

  const thisWeekDateRante = {
    from: moment().startOf('week').toDate(),
    to: moment().endOf('week').toDate(),
  };

  const thisMonthDateRante = {
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate(),
  };

  const thisQuarterDateRante = {
    from: moment().startOf('quarter').toDate(),
    to: moment().endOf('quarter').toDate(),
  };

  const thisYearDateRante = {
    from: moment().startOf('year').toDate(),
    to: moment().endOf('year').toDate(),
  };

  const dateRanges = [
    thisDayDateRante,
    thisWeekDateRante,
    thisMonthDateRante,
    thisQuarterDateRante,
    thisYearDateRante,
    null,
  ];

  const consumerColor = theme.palette.secondary.main;
  const spotColor = theme.palette.primary.main;
  const artistColor = theme.palette.error.main;

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  if (Device.isMobileOnly) {
    return (
      <Stack
        p={SPACING}
        spacing={SPACING}
        width="100%"
        sx={{
          backgroundColor:
            status === 'disabled'
              ? lighten(theme.palette.background.default, 0.01)
              : theme.palette.background.paper,
          border:
            status === 'disabled' ? `1px solid ${alpha(theme.palette.background.paper, 0.8)}` : undefined,
        }}
      >
        {status === 'active' && (
          <>
            <Typography fontWeight="bold">Statistiques</Typography>
            <StatsLineChart counts={counts} />
          </>
        )}
      </Stack>
    );
  }

  if (Device.isDesktop) {
    return (
      <Stack
        p={SPACING}
        spacing={SPACING}
        width="100%"
        sx={{
          backgroundColor:
            status === 'disabled'
              ? lighten(theme.palette.background.default, 0.01)
              : theme.palette.background.paper,
          border:
            status === 'disabled' ? `1px solid ${alpha(theme.palette.background.paper, 0.8)}` : undefined,
        }}
      >
        {status === 'active' && (
          <>
            <Typography fontWeight="bold">Statistiques</Typography>
            <StatsLineChart counts={counts} />
          </>
        )}
      </Stack>
    );
  }

  return null;
}

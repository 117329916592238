import React from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useReactiveVar } from '@apollo/client';
import { ButtonProps } from '@mui/material/Button';
import * as Device from 'react-device-detect';

import { loginStatusVar } from '@context';
import Login from './login';
import HeroSection from './HeroSection';
import Signup from './signup';

export interface LoginDialogProps {
  button?: React.ReactElement<ButtonProps>;
}

export function AuthenticationDialog(props: Readonly<LoginDialogProps>) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const loginStatus = useReactiveVar(loginStatusVar);
  const { isLoggedIn, isSessionExpired, shouldLogFirst } = loginStatus;
  const { isLandscape } = Device.useMobileOrientation();

  const isFullScreenDisplay = Device.isDesktop && !isSessionExpired;
  const isLandscapeMobile = Device.isMobileOnly && isLandscape;

  React.useEffect(() => {
    setOpen(!isLoggedIn && (isSessionExpired || shouldLogFirst));
  }, [isLoggedIn, isSessionExpired, shouldLogFirst]);

  const handleClickOpen = async () => {
    await requestPermissions();
    setOpen(true);
  };

  const handleClickClose = (event: React.MouseEvent, reason: string) => {
    loginStatusVar({
      ...loginStatus,
      isSessionExpired: false,
      shouldLogFirst: false,
      redirection: null,
    });

    if (!isSessionExpired && !shouldLogFirst && !isLoggedIn) {
      setOpen(false);
    }
  };

  return (
    <>
      {props.button &&
        React.cloneElement(props.button, {
          disabled: loginStatus.isLoggedIn,
          loading: loginStatus.isLogging,
          onClick: () => {
            handleClickOpen();
          },
        })}
      <Dialog
        scroll="body"
        fullScreen={isLandscapeMobile}
        fullWidth
        open={open}
        maxWidth={Device.isDesktop ? 'xs' : 'xl'}
        onClose={handleClickClose}
        slotProps={{
          paper: {
            sx: {
              border: '1px solid rgba(255, 255, 255, 0.2)',
            },
          },
        }}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }}
      >
        <Stack p={isLandscapeMobile ? 2 : 5} height="100%" sx={{ position: 'relative' }}>
          <IconButton onClick={handleClickClose} sx={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }}>
            <theme.icons.close sx={{ color: theme.palette.text.secondary }} />
          </IconButton>
          <Login />
        </Stack>
      </Dialog>
    </>
  );
}

async function requestPermissions() {
  if (typeof window !== 'undefined' && typeof Notification !== 'undefined') {
    let permission = 'granted';

    if (Notification.permission === 'default') {
      permission = await Notification.requestPermission();
    }

    return permission === 'granted';
  } else {
    console.warn('Notifications not supported in this browser.');
  }
}

import { useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { BookingStatusEnum, GetConsumerBookingQuery } from '@graphql';
import { getStatusColor } from '@utils';

interface BookingConsumerProps {
  booking: GetConsumerBookingQuery['booking'];
  handleEdit: () => void;
}

export default function BookingConsumer(props: Readonly<BookingConsumerProps>) {
  const { booking, handleEdit } = props;
  const { houseNumber, street, city, postalCode } = booking.spot.address;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('booking');
  const theme = useTheme();

  const lastConsumerUpdateStatus = booking.updates
    .filter((update) => update.status && update.author.__typename === 'Consumer')
    .pop();

  const handleClickSpot = () => {
    navigate(pathname + `?s=${booking.spot.slug}`, {
      preventScrollReset: true,
      state: { from: pathname },
    });
  };

  return (
    <Stack spacing={5} width="100%" height="100%" justifyContent="space-between">
      {lastConsumerUpdateStatus?.status === BookingStatusEnum.Canceled && (
        <Alert severity="warning">{t('canceledByConsumer')}</Alert>
      )}
      <Stack spacing={2} width="100%" divider={<Divider />}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="body2">{t('dataGrid.Status')}</Typography>
          <Chip
            label={t(`status.${booking.status}`, { ns: 'booking' })}
            color={getStatusColor(booking.status)}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="body2">{t('title.Spot', { ns: 'spot' })}</Typography>
          <Chip
            onClick={handleClickSpot}
            avatar={<Avatar alt="avatar" src={booking.spot.avatar?.url} />}
            label={booking.spot.name}
            sx={{ backgroundColor: 'black' }}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="body2">{t('dataGrid.Date')}</Typography>
          <Typography variant="body2">{moment(booking.startTime).format('dddd LL')}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="body2">{t('dataGrid.Hour')}</Typography>
          <Typography variant="body2">{getHour(booking)}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="body2">{t('dataGrid.Participants')}</Typography>
          <Typography variant="body2">{booking.participants}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="body2">{t('dataGrid.Occasion')}</Typography>
          <Typography variant="body2">
            {booking.occasion ? t(`occasion.${booking.occasion}`) : t(`word.null`, { ns: 'common' })}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Address')}</Typography>
          <Stack alignItems="flex-end">
            <Typography variant="body2">{`${houseNumber} ${street}`}</Typography>
            <Typography variant="body2">{`${postalCode} ${city}`}</Typography>
          </Stack>
        </Stack>
      </Stack>
      {booking?.status !== BookingStatusEnum.Canceled && (
        <Button variant="outlined" color="uncolored" startIcon={<theme.icons.edit />} onClick={handleEdit}>
          {t('update', { ns: 'cta' })}
        </Button>
      )}
    </Stack>
  );
}

function getHour(booking: GetConsumerBookingQuery['booking']) {
  const nearbyTime = moment(booking.startTime).fromNow();
  let date = moment(booking.startTime).format('HH:mm ');

  if (moment().isSame(booking.startTime, 'day')) {
    date += ` (${nearbyTime})`;
  }

  return date;
}

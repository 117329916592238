import * as React from 'react';
import { Theme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Dialog from '@mui/material/Dialog';
import { useReactiveVar } from '@apollo/client';
import * as Device from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { useAppContext, conversationIdVar } from '@context';
import { AuthenticationDialog } from '@components';
import { useSession } from '@hooks';
import { AccountButton, AccountDrawer } from './account';
import { ConversationButton, ConversationDrawer, ConversationDialog } from './conversation';
import { NotificationButton, NotificationDrawer } from './notification';
import { AccountSettingsDialog, NotificationSettingsDialog, UserSettingsDialog } from './account/dialog';

export default function AccountHeader() {
  const { user } = useSession();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [drawer, setDrawer] = React.useState<'inbox' | 'account' | 'notiication' | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogMenu, setDialogMenu] = React.useState<string | null>(null);
  const { dispatch } = useAppContext();
  const conversationId = useReactiveVar(conversationIdVar);
  const { t } = useTranslation('account');

  const handleOpenDrawer = (event: React.MouseEvent<HTMLElement>) => {
    const dataId = event.currentTarget.getAttribute('data-id');
    setDrawer(dataId as 'inbox' | 'account' | 'notiication' | null);
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseDrawer = () => {
    setAnchorElUser(null);
  };

  const handleOpenDialog = (event: React.MouseEvent<HTMLElement>) => {
    const dataId = event.currentTarget.getAttribute('data-id');
    const conversationId = event.currentTarget.getAttribute('conversation-id');

    if (drawer === 'inbox') {
      if (conversationId !== null) {
        dispatch({ type: 'SET_CONVERSATION', payload: { conversationId } });
      } else {
        dispatch({ type: 'UNSET_CONVERSATION' });
      }
    }

    setDialogMenu(dataId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    conversationIdVar(null);
  };

  const renderDialog = () => {
    if (drawer === 'inbox') {
      return <ConversationDialog handleCloseDialog={handleCloseDialog} />;
    } else if (drawer === 'account') {
      switch (dialogMenu) {
        case 'account-settings':
          return <AccountSettingsDialog handleCloseDialog={handleCloseDialog} />;
        case 'user-settings':
          return <UserSettingsDialog handleCloseDialog={handleCloseDialog} />;
        case 'notification-settings':
          return <NotificationSettingsDialog handleCloseDialog={handleCloseDialog} />;
        default:
          return null;
      }
    }
  };

  const renderDrawer = () => {
    switch (drawer) {
      case 'inbox':
        return <ConversationDrawer handleOpenDialog={handleOpenDialog} />;
      case 'notiication':
        return <NotificationDrawer handleOpenDialog={handleOpenDialog} />;
      case 'account':
        return <AccountDrawer handleOpenDialog={handleOpenDialog} />;
      default:
        return null;
    }
  };

  React.useEffect(() => {
    if (!user && anchorElUser) {
      setAnchorElUser(null);
    }
  }, [user]);

  React.useEffect(() => {
    if (conversationId) {
      dispatch({ type: 'SET_CONVERSATION', payload: { conversationId } });
      setDrawer('inbox');
      setDialogMenu('message');
      setOpenDialog(true);
    } else {
      dispatch({ type: 'UNSET_CONVERSATION' });
    }
  }, [conversationId]);

  // Case when user is not logged in
  if (!user) {
    return (
      <AuthenticationDialog
        button={
          <Button variant="contained" size="small" sx={{ ml: 'auto' }}>
            {t('connection', { ns: 'cta' })}
          </Button>
        }
      />
    );
  }

  // Case when user is logged in
  return (
    <>
      <Stack marginLeft="auto" direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
        <NotificationButton handleOpenDrawer={handleOpenDrawer} />
        <ConversationButton handleOpenDrawer={handleOpenDrawer} />
        <AccountButton handleOpenDrawer={handleOpenDrawer} />
      </Stack>
      <Drawer
        anchor={'right'}
        open={!!anchorElUser}
        onClose={handleCloseDrawer}
        sx={{
          zIndex: (theme: Theme) => theme.zIndex.drawer + 2,
        }}
      >
        {renderDrawer()}
      </Drawer>
      <Dialog
        onClose={handleCloseDialog}
        open={openDialog}
        scroll="paper"
        fullScreen={Device.isMobileOnly}
        fullWidth
        maxWidth="sm"
        slots={{
          transition: Transition,
        }}
      >
        {renderDialog()}
      </Dialog>
    </>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

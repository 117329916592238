import * as React from 'react';
import { useTheme } from '@mui/material';
import Cropper from 'react-easy-crop';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';

import { MAX_FILE_SIZE } from '@utils';

interface ImageUploadProps {
  label?: string;
  onImageProcessed: (file: File | null) => void;
  onImageSelected?: (files: File[]) => void;
  initialPreview?: string | null;
}

const MAX_WIDTH = 1400;
const MAX_HEIGHT = 788;

export const ImageUpload: React.FC<ImageUploadProps> = (props: ImageUploadProps) => {
  const { onImageProcessed, onImageSelected, label = 'Add', initialPreview } = props;
  const theme = useTheme();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [isResized, setIsResized] = React.useState(false);
  const [confirmDeletion, setConfirmDeletion] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [preview, setPreview] = React.useState<string | null>(initialPreview || null);
  const { t } = useTranslation('account');

  const handleImageSelected = (files: File[]) => {
    const [file] = files;

    if (file) {
      // original code
      // if (files.length > 0) {
      //   const reader = new FileReader();
      //   reader.readAsDataURL(files[0]);
      //   reader.onload = () => {
      //     setImageSrc(reader.result as string);
      //     setOpen(true);
      //   };
      // }

      const reader = new FileReader();

      if (file.size > MAX_FILE_SIZE) {
        const errorMessage = t('avatar.entity too large', {
          size: MAX_FILE_SIZE / 1024 / 1024 + ' Mo',
        });
        setError(errorMessage);
        return;
      }

      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;

        image.onload = () => {
          if (image.width >= 300 && image.height >= 300) {
            setImageSrc(reader.result as string);
            setOpen(true);
          } else {
            const errorMessage = t('avatar.entity too small', {
              width: MAX_WIDTH,
              height: MAX_HEIGHT,
              selectedWidth: image.width,
              selectedHeight: image.height,
            });
            setError(errorMessage);
            return;
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = React.useCallback((_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    if (!imageSrc || !croppedAreaPixels) {
      import.meta.env.DEV && console.warn('No image or cropping area defined');
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = imageSrc;
    await image.decode();

    const { width, height, x, y } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;
    ctx?.drawImage(image, x, y, width, height, 0, 0, width, height);

    canvas.toBlob(
      async (blob) => {
        if (blob) {
          const webpBlob = await new Promise<Blob | null>(
            (resolve) => canvas.toBlob(resolve, 'image/webp', 0.8) // quality at 80%
          );

          if (webpBlob) {
            const webpFile = new File([webpBlob], 'croppedImage.webp', { type: 'image/webp' });
            onImageProcessed(webpFile);

            // add preview
            const previewUrl = URL.createObjectURL(blob);
            setPreview(previewUrl);
          } else {
            import.meta.env.DEV && console.error('Error: WebP Blob not created');
          }
        } else {
          import.meta.env.DEV && console.error('Error: Blob not created');
        }
        setOpen(false);
      },
      'image/jpeg', // Initial type for the blob cropping
      1.0 // Initial quality of the cropped image
    );

    // original code
    // canvas.toBlob((blob) => {
    //   if (blob) {
    //     const croppedFile = new File([blob], 'croppedImage.jpg', { type: 'image/jpeg' });
    //     onImageProcessed(croppedFile);

    //     // add preview
    //     const previewUrl = URL.createObjectURL(blob);
    //     setPreview(previewUrl);
    //     setOpen(false);
    //   } else {
    //     import.meta.env.DEV && console.error('Error: Blob not created');
    //   }
    // });
  };

  const handleRemoveImage = () => {
    setPreview(null);
    setImageSrc(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    onImageProcessed(null);
  };

  return (
    <Box textAlign="center" width="100%">
      {/* UPLOAD BUTTON OR PREVIEW  */}
      {preview ? (
        <Box
          sx={{
            width: '100%',
            aspectRatio: '16/9',
            position: 'relative',
            overflow: 'hidden',
            border: '1px solid grey',
          }}
        >
          <img
            src={preview}
            alt="Aperçu de la couverture"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Button
            variant="contained"
            aria-label="remove image"
            onClick={handleRemoveImage}
            startIcon={<theme.icons.delete />}
            color="uncolored"
            fontSize="small"
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            {t('remove', { ns: 'cta' })}
          </Button>
        </Box>
      ) : (
        <Stack
          width="100%"
          alignItems="center"
          justifyContent="center"
          py={5}
          sx={{
            backgroundColor: theme.palette.background.default,
            border: `1px dashed ${theme.palette.grey[600]}`,
          }}
        >
          <Button component="label" variant="contained" startIcon={<theme.icons.upload />}>
            {label}
            <input
              type="file"
              accept=".png, .jpg, .jpeg, .webp"
              hidden
              onChange={(event) => {
                handleImageSelected(Array.from(event.target.files || []));
                event.target.value = ''; // allow to select the same file again
              }}
            />
          </Button>
        </Stack>
      )}

      {/* CROP MODAL */}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth fullScreen={Device.isMobile}>
        <DialogTitle>{t('avatar.Crop the image')}</DialogTitle>
        <DialogContent>
          <Alert severity="info" sx={{ mb: 2, p: 2 }}>
            {t('avatar.requirements', {
              width: MAX_WIDTH,
              height: MAX_HEIGHT,
              size: MAX_FILE_SIZE / 1024 / 1024 + ' Mo',
            })}
          </Alert>
          {imageSrc && (
            <Box position="relative" width="100%" height="400px" bgcolor="grey.900">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={16 / 9}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                style={{
                  containerStyle: { width: '100%', height: '100%' },
                  mediaStyle: { maxWidth: '100%', maxHeight: '100%' },
                }}
              />
            </Box>
          )}
          <Stack mt={5}>
            {error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}
            <Typography>{t('avatar.Zoom')}</Typography>
            <Stack px={2}>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(_e: any, newZoom: number | number[]) => setZoom(newZoom as number)}
                aria-labelledby="Zoom"
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={() => setOpen(false)} variant="outlined" color="uncolored">
            {t('cancel', { ns: 'cta' })}
          </Button>
          <Button onClick={handleCrop} variant="contained" color="success" fullWidth>
            {t('crop & save', { ns: 'cta' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

import { alpha, useTheme } from '@mui/material';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import { GetAccountCountsQuery } from '@graphql';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface AccountDoughnutProps {
  counts: GetAccountCountsQuery['accountCounts'] | null;
}

export default function StatsLineChart(props: AccountDoughnutProps) {
  const { counts } = props;
  const theme = useTheme();

  const consumerColor = theme.palette.secondary.main;
  const spotColor = theme.palette.primary.main;
  const artistColor = theme.palette.error.main;

  const days = [1, 2, 3, 4, 5, 6, 0].map((day) => moment().day(day).format('ddd'));
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => moment().month(month).format('MMM'));

  const dataEx = {
    labels: days,
    datasets: [
      {
        label: 'Vues',
        data: [0, 0, 0, 0, 1, 2, 1],
        borderColor: theme.palette.primary.light,
        backgroundColor: alpha(theme.palette.primary.light, 0.2),
        borderWidth: 2,
        tension: 0.3,
      },
      {
        label: 'Transformation',
        data: [30, 60, -12, 70, 50, 90, 45],
        borderColor: theme.palette.secondary.main,
        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
        borderWidth: 2,
        tension: 0.3,
      },
      {
        label: 'Rebonds',
        data: [30, 45, 60, 50, 70, 90, 33].reverse(),
        borderColor: theme.palette.success.main,
        backgroundColor: alpha(theme.palette.success.main, 0.2),
        borderWidth: 2,
        tension: 0.3,
      },
    ],
  };

  const data = {
    labels: ['Vues', 'Transformation', 'Rebonds'],
    datasets: [
      {
        label: 'Comptes',
        data: [counts?.current.consumer, counts?.current.spot, counts?.current.artist],
        backgroundColor: [consumerColor, spotColor, artistColor],
        borderColor: theme.palette.background.paper,
        borderWidth: 2,
      },
    ],
  };

  const optionsEx = {
    responsive: true,
    maintainAspectRatio: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Statistiques',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            return `${context.raw > 0 ? '+' : ''} ${context.raw}`.trim();
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Temps',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)', // Couleur des lignes de fond
        },
      },
      y: {
        title: {
          display: true,
          text: 'Taux',
        },
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.2)', // Couleur des lignes de fond
        },
      },
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'right' as const,
        labels: {
          color: theme.palette.text.primary,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            return `${context.label}: ${context.raw}%`;
          },
        },
      },
    },
  };

  return <Line data={dataEx} options={optionsEx} />;
  // return counts ? <Line data={dataEx} options={optionsEx} /> : null;
}

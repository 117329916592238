import { makeVar } from '@apollo/client';

type loginStatusVar = {
  isLoggedIn: boolean;
  isLogging: boolean;
  forceGetSession: boolean;
  mustFinalizeRegister: boolean;
  isSessionExpired: boolean;
  shouldLogFirst: boolean;
  redirection: string | null;
};

export const defaultLoginStatus: loginStatusVar = {
  isLoggedIn: false,
  isLogging: false,
  forceGetSession: false,
  mustFinalizeRegister: false,
  isSessionExpired: false,
  shouldLogFirst: false,
  redirection: null,
};

export const loginStatusVar = makeVar<loginStatusVar>(defaultLoginStatus);
export const languageVar = makeVar('fr');
export const showAppLoaderVar = makeVar(false);
export const conversationIdVar = makeVar<string | null>(null);
// bookings
export const previewBookingVar = makeVar<string | null>(null);
export const createBookingVar = makeVar<boolean>(false);
export const refetchBookingsVar = makeVar<boolean>(false);
// events
export const previewEventVar = makeVar<string | null>(null);
export const refetchEventsVar = makeVar<boolean>(false);

import { useReactiveVar } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  useGetSessionLazyQuery,
  useLoginMutation,
  useLogoutMutation,
  LoginMutationVariables,
  LoginMutation,
} from '@graphql';
import { loginStatusVar } from '@context';
import { useToastError } from '@hooks';

export default function useConnection() {
  const loginStatus = useReactiveVar(loginStatusVar);
  const toastError = useToastError();
  const navigate = useNavigate();
  const { t } = useTranslation('authentication');

  const [getSession] = useGetSessionLazyQuery({
    onError: toastError,
    onCompleted({ session }) {
      if (session) {
        import.meta.env.DEV && console.log(`[{session}]:`, session);

        // Update the login status
        loginStatusVar({
          ...loginStatus,
          isLogging: false,
          forceGetSession: true,
          isLoggedIn: true,
          isSessionExpired: false,
          shouldLogFirst: false,
        });

        // Display a welcome message
        toast(t('toast.success', { firstname: session.firstname }));

        // Redirect to the previous page if needed
        loginStatus.redirection && navigate(loginStatus.redirection);

        // TODO: fix this ugly hack
        // This is a workaround to reload the page when the user logs in
        // to subscribe to the push notifications
        // if ('location' in window) {
        //   // delay 2 seconds before reloading the page
        //   await new Promise((resolve) => setTimeout(resolve, 2000));

        // usefull for the PWA push notifications
        //   window.location.reload();
        // }
      } else {
        import.meta.env.DEV && console.log('Get session failed at login.');
        loginStatusVar({
          ...loginStatus,
          isLogging: false,
          isLoggedIn: false,
          isSessionExpired: false,
          shouldLogFirst: true,
        });
      }
    },
  });

  const [logUser] = useLoginMutation({
    onError(error: any) {
      toastError(error);
      loginStatusVar({
        ...loginStatus,
        isLogging: false,
      });
    },
    onCompleted({ login }) {
      getSession();
    },
  });

  const login = (args: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) => {
    loginStatusVar({
      ...loginStatus,
      isLogging: true,
    });
    return logUser(args);
  };

  const [logout, { client }] = useLogoutMutation({
    async onCompleted() {
      toast(`👋 Adio !`);

      // clear the cache (unlike resetStore, clearStore does not refetch queries)
      await client.clearStore();

      // unregister push notifications
      await pushNotifUnRegister();

      // make sure to clear the cache for the following queries
      localStorage.removeItem('SPOTS_LAST_CALL');
      localStorage.removeItem('EVENTS_LAST_CALL');

      loginStatusVar({
        ...loginStatus,
        forceGetSession: true, // get session without cache
        isLoggedIn: false,
        isLogging: false,
        mustFinalizeRegister: false,
        isSessionExpired: false,
        shouldLogFirst: false,
        redirection: null,
      });

      navigate('/spots');
    },
  });

  return { logout, login };
}

async function pushNotifUnRegister() {
  try {
    import.meta.env.DEV && console.log('🔔 Unregistering push notifications');
  } catch (error) {
    console.error('[pushNotifUnRegister error]:', error);
  }
}

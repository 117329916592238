import { useTheme, lighten } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Device from 'react-device-detect';

import { GetSpotBookingQuery, GetConsumerBookingQuery, useUpdateBookingMutation } from '@graphql';
import { useSession, useToastError } from '@hooks';
import { getStatusColor } from '@utils';

interface BookingHistoryProps {
  bookingId: string;
  useNote: boolean;
  updates: GetSpotBookingQuery['booking']['updates'] | GetConsumerBookingQuery['booking']['updates'];
}

interface UdpateProps {
  update: BookingHistoryProps['updates'][0];
  isMe: boolean;
}

export default function BookingHistory(props: Readonly<BookingHistoryProps>) {
  const { bookingId, updates, useNote } = props;

  return (
    <Stack spacing={3} height="75vh" justifyContent="flex-end">
      <Stack direction="column-reverse" spacing={1} sx={{ overflowY: 'auto' }}>
        {updates.map((update) => (
          <DisplayUpdate key={update.id} update={update} />
        ))}
      </Stack>
      <AddMessage bookingId={bookingId} useNote={useNote} />
    </Stack>
  );
}

function DisplayUpdate(props: Readonly<Omit<UdpateProps, 'isMe'>>) {
  const { update } = props;
  const theme = useTheme();
  const { account } = useSession();
  const avatarSize = Device.isMobileOnly ? 24 : 32;
  const isMe = account?.id === update.author.id;

  return (
    <Stack spacing={1} width="100%">
      <Stack
        direction="row"
        spacing={1}
        width="100%"
        height="100%"
        alignItems="flex-end"
        justifyContent={isMe ? 'flex-end' : 'flex-start'}
      >
        {!isMe && (
          <Tooltip title={update.author.name}>
            <Avatar
              src={update.author.avatar?.url}
              alt={update.author.name}
              sx={{ width: avatarSize, height: avatarSize }}
            />
          </Tooltip>
        )}
        <Stack
          p={2}
          maxWidth="80%"
          height="100%"
          sx={{
            backgroundColor: isMe
              ? theme.palette.background.default
              : lighten(theme.palette.background.default, 0.15),
            borderRadius: 4,
          }}
        >
          {update.status && <DisplayStatus update={update} isMe={isMe} />}
          {update.message && <DisplayMessage update={update} isMe={isMe} />}
        </Stack>
      </Stack>
    </Stack>
  );
}

function DisplayMessage(props: Readonly<UdpateProps>) {
  const { update } = props;

  return (
    <Stack>
      <Typography>
        {update.message ??
          `lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a diam lectus. Sed sit amet ipsum
          mauris. lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a diam lectus. Sed sit amet
          ipsum mauris.`}
      </Typography>
      <DisplayDate {...props} />
    </Stack>
  );
}

function DisplayStatus(props: Readonly<UdpateProps>) {
  const { update } = props;
  const { t } = useTranslation('booking');

  if (!update.status) {
    return null;
  }

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t('update.status.The status of the booking is')}</Typography>
        <Chip label={t(`status.${update.status}`)} color={getStatusColor(update.status)} size="small" />
      </Stack>
      <DisplayDate {...props} />
    </Stack>
  );
}

function DisplayDate(props: Readonly<UdpateProps>) {
  const { update, isMe } = props;

  return (
    <Stack direction="row" justifyContent={isMe ? 'flex-end' : 'flex-start'} width="100%">
      <Typography variant="caption" color="text.secondary">
        {moment().isSame(update.createdAt, 'day')
          ? moment(update.createdAt).format('HH:mm')
          : moment(update.createdAt).fromNow()}
      </Typography>
    </Stack>
  );
}

function AddMessage(props: Readonly<{ bookingId: string; useNote: boolean }>) {
  const { bookingId, useNote } = props;
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [udpateBooking] = useUpdateBookingMutation({
    refetchQueries: ['GetSpotBooking', 'GetConsumerBooking'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      formik.resetForm();
    },
    onError: toastError,
  });

  const formik = useFormik({
    initialValues: { message: '' },
    onSubmit: async (values) => {
      udpateBooking({
        variables: {
          input: {
            id: bookingId,
            message: values.message,
          },
        },
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="row" spacing={1} width="100%">
        <TextField
          fullWidth
          size="small"
          autoComplete="off"
          name="message"
          label={t(`word.${useNote ? 'Note' : 'Message'}`, { ns: 'common' })}
          variant="outlined"
          value={formik.values.message}
          onChange={formik.handleChange}
        />
        <Button
          disabled={!formik.values.message.trim().length}
          size="small"
          type="submit"
          variant="contained"
          color="primary"
        >
          {t(useNote ? 'add' : 'send')}
        </Button>
      </Stack>
    </form>
  );
}

import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from '@mui/system';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom';
import { register } from 'swiper/element/bundle';

import 'moment/dist/locale/fr';
import 'moment/dist/locale/es';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './index.css';
import './i18n';

import { AppProvider } from '@context';
import { PushNotificationManager, InstallPWA } from '@components';
import { useSession } from '@hooks';
import { lightTheme, darkTheme } from './theme/Theme';
import { buildRouter } from './routes';

export default function App() {
  const [useDefaultTheme, toggle] = React.useReducer((theme) => !theme, true);
  const session = useSession();
  let theme = createTheme(useDefaultTheme ? darkTheme : lightTheme);
  theme = responsiveFontSizes(theme);
  register();

  const helmetContext = {};
  const router = React.useMemo(() => buildRouter(session), [session]);

  return (
    <HelmetProvider context={helmetContext}>
      <AppProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <InstallPWA />
            {/* <PushNotificationManager /> */}
            <RouterProvider
              future={{
                v7_startTransition: true,
                v7_relativeSplatPath: true,
              }}
              router={router}
            />
          </ThemeProvider>
        </LocalizationProvider>
      </AppProvider>
    </HelmetProvider>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';

import SignupForm from './signup-form';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignuptDialog() {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState<'consumer' | 'spot' | 'artist'>('consumer');
  const { t } = useTranslation('authentication');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    setType('consumer');
  };

  return (
    <React.Fragment>
      <Stack width="100%" spacing={1} direction="row" justifyContent="flex-start">
        <Typography variant="body2" textAlign="left" fontSize={{ xs: '.7rem', sm: '.8rem' }}>
          {t('No account yet?')}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="bold"
          fontSize={{ xs: '.7rem', sm: '.8rem' }}
          onClick={handleClickOpen}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
              color: 'text.primary',
            },
          }}
        >
          {t('signup', { ns: 'cta' })}
        </Typography>
      </Stack>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullScreen
        onClose={handleClose}
        aria-describedby="singup-dialog-slide-description"
        sx={{ maxHeight: '100vh' }}
      >
        <Stack direction="row" p={3} justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            {t('Registration')}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            sx={{ color: 'grey' }}
            onClick={handleClose}
          >
            {t('close', { ns: 'cta' })}
          </Button>
        </Stack>
        <Stack spacing={6} p={3} height="100%">
          <Typography textAlign="center" color="textSecondary">
            {t('Register now, book your favorite spots and discover new ones!')}
          </Typography>
          <SignupForm type={type} handleBack={handleBack} />
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}

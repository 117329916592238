import * as React from 'react';
import { useTheme } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import * as Device from 'react-device-detect';

import { useAppContext } from '@context';
import { useSession, useToastError } from '@hooks';
import { useGetConversationQuery, useSendMessageMutation, GetConversationQuery } from '@graphql';
import { Conversation, Header } from './components';
import { getConversationTitle } from '@utils';

interface ConversationDialogProps {
  handleCloseDialog: () => void;
}

export function ConversationDialog(props: Readonly<ConversationDialogProps>) {
  const { handleCloseDialog } = props;
  const theme = useTheme();
  const { state } = useAppContext();
  const { user, account } = useSession();
  const toastError = useToastError();
  const [conversation, setConversation] = React.useState<GetConversationQuery['conversation'] | null>(null);
  const { t } = useTranslation('conversation');

  useGetConversationQuery({
    skip: !account || state.conversationId === null,
    variables: {
      conversationId: state.conversationId ?? '',
    },
    onCompleted: ({ conversation }) => {
      if (conversation) {
        setConversation({
          ...conversation,
          messages: _.orderBy(conversation.messages, ['createdAt'], ['desc']),
        });
      }
    },
  });

  const [sendMessage] = useSendMessageMutation();

  const formik = useFormik({
    initialValues: {
      content: '',
    },
    validationSchema: yup.object({
      content: yup.string().min(1).required(),
    }),
    onSubmit: (values, { resetForm }) => {
      sendMessage({
        variables: {
          conversationId: state.conversationId || '',
          content: values.content,
        },
        onCompleted() {
          resetForm();
        },
        onError: toastError,
      });
    },
  });

  const handleLineBreak = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      const textarea = e.target as HTMLTextAreaElement;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      formik.setFieldValue(
        'content',
        formik.values.content.substring(0, start) + '\n' + formik.values.content.substring(end)
      );

      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + 1;
      }, 0);
    } else if (e.key === 'Enter') {
      formik.submitForm();
    }
  };

  if (!user || state.conversationId === null || !conversation) {
    return null;
  }

  return (
    <Stack height={Device.isMobileOnly ? undefined : '95vh'}>
      <Header conversation={conversation} handleCloseDialog={handleCloseDialog} />
      <Divider sx={{ backgroundColor: theme.palette.grey[800] }} />
      <Conversation conversationId={state.conversationId} />
      <DialogActions sx={{ p: theme.spacing(1) }}>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Stack direction="row" width="100%" spacing={1}>
            <TextField
              autoComplete="off"
              placeholder={t('Write...')}
              variant="outlined"
              fullWidth
              id="content"
              name="content"
              multiline
              maxRows={4}
              value={formik.values.content}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={handleLineBreak}
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: theme.borderRadius,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.grey[800],
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.grey[800],
                    borderWidth: '1px',
                  },
                },
                input: {
                  color: theme.palette.text.primary,
                },
                textarea: {
                  color: theme.palette.text.primary,
                  overflowY: 'auto',
                  resize: 'none',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ alignSelf: 'flex-end' }}>
                    <IconButton
                      disabled={!formik.dirty || !formik.isValid}
                      aria-label="send"
                      variant="contained"
                      size="large"
                      type="submit"
                      sx={{
                        color: theme.palette.text.secondary,
                        borderRadius: theme.borderRadius,
                        backgroundColor: theme.palette.primary.main,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.text.primary,
                        },
                      }}
                    >
                      <theme.icons.telegram />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </form>
      </DialogActions>
    </Stack>
  );
}

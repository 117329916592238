import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import * as Device from 'react-device-detect';

import { useGetSpotStatsQuery, GetSpotStatsQuery } from '@graphql';
import { CountSlot, StatsSlot } from './charts';
import { Card } from './card';

const thisDayDateRante = {
  from: moment().startOf('day').toDate(),
  to: moment().endOf('day').toDate(),
};

const thisWeekDateRante = {
  from: moment().startOf('week').toDate(),
  to: moment().endOf('week').toDate(),
};

const thisMonthDateRante = {
  from: moment().startOf('month').toDate(),
  to: moment().endOf('month').toDate(),
};

const thisQuarterDateRante = {
  from: moment().startOf('quarter').toDate(),
  to: moment().endOf('quarter').toDate(),
};

const thisYearDateRante = {
  from: moment().startOf('year').toDate(),
  to: moment().endOf('year').toDate(),
};

const ranges: Array<'day' | 'week' | 'month' | 'quarter' | 'year' | 'always'> = [
  'day',
  'week',
  'month',
  'quarter',
  'year',
  'always',
];
const dateRanges = [
  thisDayDateRante,
  thisWeekDateRante,
  thisMonthDateRante,
  thisQuarterDateRante,
  thisYearDateRante,
  null,
];

export function MyDashboardPage() {
  const [variables, setVariables] = React.useState<{
    range: string;
    dateRange: { from: Date; to: Date } | null;
  }>({ range: 'day', dateRange: thisDayDateRante });
  const [stats, setStats] = React.useState<GetSpotStatsQuery['getSpotStats'] | null>(null);
  // const [stats, setStats] = React.useState<Omit<GetSpotStatsQuery['getSpotStats'], 'id'> | null>(null);
  const [tab, setTab] = React.useState(0);
  const { isLandscape } = Device.useMobileOrientation();
  const theme = useTheme();

  const stackSx = {
    backgroundColor: theme.palette.background.paper,
    cursor: 'default',
  };

  useGetSpotStatsQuery({
    variables: variables,
    onCompleted({ getSpotStats }) {
      setStats(getSpotStats);
    },
  });

  React.useEffect(() => {
    setVariables({ range: ranges[tab], dateRange: dateRanges[tab] });
  }, [tab]);

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  function getPeriod() {
    if (tab === 0) {
      return "aujourd'hui";
    } else if (tab === dateRanges.length - 1) {
      return 'toujours';
    }
    return `du ${moment(dateRanges[tab]?.from).format('LL')} au ${moment(dateRanges[tab]?.to).format('LL')}`;
  }

  if (Device.isMobileOnly) {
    return (
      <Stack spacing={2} p={2} pb={9}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" p={2} sx={stackSx}>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Jour" />
            <Tab label="Semaine" />
            <Tab label="Mois" />
            <Tab label="Trimestre" />
            <Tab label="Année" />
            <Tab label="Toujours" />
          </Tabs>
        </Stack>
        <Typography variant="body2" component="h6">
          {getPeriod()}
        </Typography>
        {isLandscape ? (
          <>
            <Stack spacing={2} justifyContent="center" alignItems="center" width="100%">
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" width="100%">
                <Card type="addicts" value={stats?.addicts} />
                <Card type="bookings" value={stats?.bookings} />
                <Card type="events" value={stats?.events} />
              </Stack>
              <CountSlot period={ranges[tab]} stats={stats} />
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" width="100%">
                <Card status="disabled" type="transactions" value={{ current: 23, previous: 23 }} />
                <Card status="disabled" type="views" value={{ current: 1001, previous: 1323 }} />
                <Card status="disabled" type="visits" value={{ current: 290, previous: 182 }} />
              </Stack>
              <StatsSlot />
            </Stack>
          </>
        ) : (
          <>
            <Stack spacing={2} justifyContent="center" alignItems="center" width="100%">
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" width="100%">
                <Card type="addicts" value={stats?.addicts} />
                <Card status="disabled" type="transactions" value={{}} />
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" width="100%">
                <Card type="bookings" value={stats?.bookings} />
                <Card status="disabled" type="views" value={{}} />
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" width="100%">
                <Card type="events" value={stats?.events} />
                <Card status="disabled" type="visits" value={{}} />
              </Stack>
            </Stack>
            <Stack spacing={2} width="100%">
              <CountSlot period={ranges[tab]} stats={stats} />
              <StatsSlot status="disabled" />
            </Stack>
          </>
        )}
      </Stack>
    );
  }

  return (
    <Stack spacing={2} p={2}>
      <Stack direction="row" p={2} sx={{ ...stackSx, justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">{getPeriod()}</Typography>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
        >
          <Tab label="Jour" />
          <Tab label="Semaine" />
          <Tab label="Mois" />
          <Tab label="Trimestre" />
          <Tab label="Année" />
          <Tab label="Toujours" />
        </Tabs>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" width="100%">
        <Card type="addicts" value={stats?.addicts} />
        <Card type="bookings" value={stats?.bookings} />
        <Card type="events" value={stats?.events} />
        <Card status="disabled" type="transactions" value={{}} />
        <Card status="disabled" type="views" value={{}} />
        <Card status="disabled" type="visits" value={{}} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <CountSlot period={ranges[tab]} stats={stats} />
        <StatsSlot status="disabled" />
      </Stack>
    </Stack>
  );
}

import React from 'react';
import { toast } from 'react-toastify';
import { useReactiveVar } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { usePushSubscription } from '@hooks';
import { loginStatusVar } from '@context';

const isDev = import.meta.env?.DEV ?? false;

interface ShowPushNotificationProps {
  title: string;
  body: string;
  lang: string;
  data: {
    status: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO';
    type?: string | null;
    action?: string | null;
  };
}

export const PushNotificationManager = () => {
  const { isLoggedIn } = useReactiveVar(loginStatusVar);
  const { subscribeToPushNotifications } = usePushSubscription();

  React.useEffect(() => {
    isDev && console.log('🔔 Registering push notifications');

    if (!isLoggedIn) {
      // Push notifications for now only for logged users
      isDev && console.warn('User not logged, cannot subscribe to push notifications.');
      return;
    }

    navigator.serviceWorker.ready.then((registration) => {
      if (typeof window !== 'undefined' && typeof Notification !== 'undefined') {
        if (Notification.permission === 'default') {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              subscribeToPushNotifications();
            } else {
              isDev && console.warn('Permission refused for notifications.');
            }
          });
        } else if (Notification.permission === 'granted') {
          subscribeToPushNotifications();
        }
      } else {
        isDev && console.warn('Notifications not supported in this browser.');
      }
    });
  }, [isLoggedIn, subscribeToPushNotifications]);

  return null;
};

export const ShowPushNotification = (props?: ShowPushNotificationProps | null) => {
  import.meta.env.DEV && console.log('✅ Notification receive from SW :', props);
  const { title, body, data } = props || {};

  if (data?.type === 'PUSH_NOTIFICATION') {
    const message = (
      <Stack spacing={0}>
        <Typography variant="body2" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body1">{body}</Typography>
      </Stack>
    );

    if (data.status === 'SUCCESS') {
      toast.success(message, { hideProgressBar: true });
    } else if (data.status === 'ERROR') {
      toast.error(message, { hideProgressBar: true });
    } else if (data.status === 'WARNING') {
      toast.warning(message, { hideProgressBar: true });
    } else if (data.status === 'INFO') {
      toast.info(message, { hideProgressBar: true });
    } else {
      toast(message, { hideProgressBar: true });
    }
  }
};

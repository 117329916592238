import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Outlet, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Device from 'react-device-detect';

import { useSession } from '@hooks';
import { createBookingVar } from '@context';
import { Calendar } from '@components';

export function MyCalendarPage() {
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { account } = useSession();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation('cta');
  const theme = useTheme();

  React.useEffect(() => {
    const date = searchParams.get('date');
    const isBefore = moment(date).isBefore(moment(), 'day');

    setIsDisabled(!!date && isBefore);
  }, [searchParams]);

  const handleOpenCreate = () => {
    const variant = pathname
      .split('/')
      .filter(Boolean)
      .find((segment) => ['booking', 'event'].includes(segment)) as 'booking' | 'event';

    if (variant === 'booking') {
      // navigate('/me/calendar/booking/create', { state: { from: pathname } });
      createBookingVar(true);
    } else if (variant === 'event') {
      const date = searchParams.get('date') ?? moment().format('YYYY-MM-DD');
      navigate(`/me/calendar/event/create?date=${date}`, { state: { from: pathname } });
    }
  };

  if (!account) {
    return null;
  }

  if (account.__typename === 'Consumer') {
    return (
      <>
        <Stack height="inherit">
          {Device.isMobile && (
            <Stack p={2}>
              <Button
                disabled={isDisabled}
                variant="outlined"
                color="primary"
                fullWidth={Device.isMobile}
                onClick={handleOpenCreate}
              >
                {t('add an event')}
              </Button>
            </Stack>
          )}
          <Calendar accountId={account.id} variant="all" />
        </Stack>
        <Outlet />
      </>
    );
  }

  if (account.__typename === 'Spot') {
    const variant = pathname
      .split('/')
      .filter(Boolean)
      .find((segment) => ['booking', 'event'].includes(segment)) as 'booking' | 'event';

    return (
      <>
        <Stack height="inherit">
          {Device.isMobile && (
            <Stack p={theme.spacing(1, 1, 0, 1)}>
              <Button
                disabled={isDisabled}
                variant="outlined"
                color="primary"
                fullWidth={Device.isMobile}
                onClick={handleOpenCreate}
              >
                {variant === 'event' ? t('add an event') : t('add a booking')}
              </Button>
            </Stack>
          )}
          <Calendar accountId={account.id} variant={variant} />
        </Stack>
        <Outlet />
      </>
    );
  }

  return null;
}

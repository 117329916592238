import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useSearchParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import { useSession } from '@hooks';
import {
  useListMyBookingsQuery,
  useListMyEventsQuery,
  ListMyBookingsQuery,
  ListMyEventsQuery,
} from '@graphql';
import { refetchEventsVar, refetchBookingsVar } from '@context';
import { shouldDisableBookingDate, shouldDisableBookingTime, initBookingDateTime } from '@utils';
import BookingSlot from './BookingSlot';
import EventSlot from './EventSlot';

interface MobileCalendarProps {
  accountId: string;
  variant: 'all' | 'booking' | 'event';
}

type DateRange = { from: string; to: string } | null;
type ToDisplay = ListMyBookingsQuery['myBookings'] | ListMyEventsQuery['myEvents'];

export function MobileCalendar(props: Readonly<MobileCalendarProps>) {
  const { accountId, variant } = props;
  const theme = useTheme();
  const { account } = useSession();
  const [value, setValue] = React.useState<Moment>(moment());
  const [dateRange, setDateRange] = React.useState<DateRange>({
    from: moment().startOf('month').toISOString(),
    to: moment().endOf('month').toISOString(),
  });
  const [bookings, setBookings] = React.useState<ListMyBookingsQuery['myBookings']>([]);
  const [events, setEvents] = React.useState<ListMyEventsQuery['myEvents']>([]);
  const [toDisplay, setToDisplay] = React.useState<ToDisplay>([]);
  const [highlightedDays, setHighlightedDays] = React.useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const isRefetchEvents = useReactiveVar(refetchEventsVar);
  const isRefetchBookings = useReactiveVar(refetchBookingsVar);
  const { t } = useTranslation('common');

  const bookingQuery = useListMyBookingsQuery({
    skip: !dateRange,
    variables: { dateRange },
    onCompleted({ myBookings }) {
      setBookings(myBookings);
    },
    fetchPolicy: 'cache-and-network',
  });

  const eventQuery = useListMyEventsQuery({
    skip: !dateRange,
    variables: { dateRange },
    onCompleted({ myEvents }) {
      setEvents(myEvents);
    },
    fetchPolicy: 'cache-and-network',
  });

  const RenderDay = (props: PickersDayProps<Moment> & { highlightedDays?: number[] }) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        color="info"
        overlap="circular"
        badgeContent={isSelected ? ' ' : undefined}
        variant={isSelected ? 'dot' : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  };

  const handleMonthChange = (date: Moment) => {
    setHighlightedDays([]);
    setDateRange({
      from: date.startOf('month').toISOString(),
      to: date.endOf('month').toISOString(),
    });
  };

  const handleDateClick = (date: Moment) => {
    setValue(date);
  };

  React.useEffect(() => {
    setSearchParams({ date: value.format('YYYY-MM-DD') });

    const matchingBookings = bookings.filter((b) => moment(b.startTime).isSame(value, 'day'));
    const matchingEvents = events.filter((e) => moment(e.from).isSame(value, 'day'));

    if (variant === 'all') {
      setToDisplay([...matchingBookings, ...matchingEvents] as ToDisplay);
    } else if (variant === 'booking') {
      setToDisplay(matchingBookings);
    } else if (variant === 'event') {
      setToDisplay(matchingEvents);
    }
  }, [value]);

  React.useEffect(() => {
    const bookingDays = bookings.map((booking) => moment(booking.startTime).get('date'));
    const eventDays = events.map((event) => moment(event.from).get('date'));
    const matchingBookings = bookings.filter((b) => moment(b.startTime).isSame(value, 'day'));
    const matchingEvents = events.filter((e) => moment(e.from).isSame(value, 'day'));

    if (variant === 'all') {
      setHighlightedDays([...new Set(eventDays.concat(bookingDays))]);
      setToDisplay([...matchingEvents, ...matchingBookings] as ToDisplay);
    } else if (variant === 'booking') {
      setHighlightedDays([...new Set(bookingDays)]);
      setToDisplay(matchingBookings);
    } else if (variant === 'event') {
      setHighlightedDays([...new Set(eventDays)]);
      setToDisplay(matchingEvents);
    }
  }, [variant, bookings, events]);

  React.useEffect(() => {
    // Refetch events and bookings when necessary (new or deleted items)
    if (isRefetchEvents) {
      eventQuery.refetch({ dateRange }).then(({ data }) => {
        setEvents(data?.myEvents || []);
        refetchEventsVar(false);
      });
    }
    if (isRefetchBookings) {
      bookingQuery.refetch({ dateRange }).then(({ data }) => {
        setBookings(data?.myBookings || []);
        refetchBookingsVar(false);
      });
    }
  }, [isRefetchEvents, isRefetchBookings]);

  return (
    <Stack spacing={0} pb={1} m={0} sx={{ overflowY: 'hidden' }}>
      <DateCalendar
        loading={bookingQuery.loading || eventQuery.loading}
        renderLoading={() => <CircularProgress />}
        showDaysOutsideCurrentMonth
        displayWeekNumber
        value={value}
        onMonthChange={handleMonthChange}
        onChange={handleDateClick}
        shouldDisableDate={(date) => {
          if (account?.__typename === 'Spot') {
            const { opening, closing } = account;
            return shouldDisableBookingDate(date, opening, closing);
          }
          return false;
        }}
        sx={{ width: '100%', height: '-webkit-fill-available' }}
        slots={{
          day: RenderDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          } as any,
        }}
      />
      <Divider sx={{ backgroundColor: theme.palette.grey[600] }} />
      <Stack pt={1} pb={`${theme.mixins.toolbar.minHeight}px`} sx={{ overflowY: 'scroll' }}>
        {toDisplay.length ? (
          <Stack px={1} spacing={1} id="selected-account-event-date">
            {toDisplay.map((data) => {
              if (data.__typename === 'Booking') {
                return <BookingSlot key={data.id} booking={data} />;
              } else if (data.__typename === 'Event') {
                return <EventSlot key={data.id} event={data} />;
              }
            })}
          </Stack>
        ) : (
          <Stack px={1}>
            <Stack
              p={3}
              sx={{ backgroundColor: theme.palette.background.paper, borderRadius: theme.borderRadius }}
            >
              <Typography fontSize={{ xs: '.7rem', sm: '.8rem' }}>
                {t('calendar.Nothing this day')}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

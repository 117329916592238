import React from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import * as Device from 'react-device-detect';

import { Logo } from '@components';
import { loginStatusVar } from '@context';
import LoginDialogForm from './login-form';
import SimpleSignup from '../simple-signup';
import { GoogleConnect, FacebookConnect, AppleConnect } from './buttons';

const ConnexionAlert = () => {
  const loginStatus = useReactiveVar(loginStatusVar);
  const { t } = useTranslation('authentication');

  if (loginStatus.isSessionExpired) {
    return (
      <Alert severity="info" sx={{ width: '100%' }}>
        {t('alert.Session has expired')}
      </Alert>
    );
  } else if (loginStatus.shouldLogFirst) {
    return (
      <Alert severity="info" sx={{ width: '100%' }}>
        {t('alert.You first need to connect')}
      </Alert>
    );
  }
  return null;
};

export default function Login() {
  const { isLandscape } = Device.useMobileOrientation();
  const { t } = useTranslation('authentication');

  const isLandscapeMobile = Device.isMobileOnly && isLandscape;
  const spacing = Device.isMobileOnly ? 1 : 2;

  const dividerSx = {
    fontSize: '0.8rem',
    width: '100%',
  };

  if (isLandscapeMobile) {
    return (
      <Stack
        direction="row"
        width="100%"
        height="100%"
        justifyContent="space-between"
        spacing={spacing}
        divider={<Divider orientation="vertical" variant="middle" flexItem />}
      >
        <Stack spacing={spacing} justifyContent="center" alignItems="center" flex={1}>
          <Logo clickable={false} size={2.5} />
        </Stack>
        <Stack
          p={spacing + 5}
          spacing={spacing + 1}
          justifyContent="center"
          alignItems="center"
          divider={<Divider orientation="horizontal" flexItem />}
          flex={1}
        >
          <GoogleConnect />
          <LoginDialogForm />
          <SimpleSignup />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack width="100%" height="100%" spacing={spacing + 2} justifyContent="center" alignItems="center">
      <Stack py={3}>
        <Logo clickable={false} size={2.5} />
      </Stack>
      {Device.isDesktop && <ConnexionAlert />}
      <Stack spacing={spacing} width="100%">
        <GoogleConnect />
      </Stack>
      <Divider sx={dividerSx}>{t('text.or')}</Divider>
      <LoginDialogForm />
      <SimpleSignup />
    </Stack>
  );
}

import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';

import App from './App';
import apolloClient from './apollo';
import { ShowPushNotification } from '@components';
import { GetSessionDocument, SavePushSubscriptionDocument } from '@graphql';

const isDev = import.meta.env?.DEV ?? false;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

if ('serviceWorker' in navigator) {
  const swFile = import.meta.env.DEV ? './dev-sw.js?dev-sw' : './sw.js';

  navigator.serviceWorker
    .register(swFile, { type: 'module', scope: '/' })
    .then(async (registration) => {
      isDev && console.log('Service Worker registered successfully:', registration);

      await subscribeToPushNotifications();

      // Listen to messages from the Service Worker
      navigator.serviceWorker.addEventListener('message', (event) => {
        import.meta.env.DEV &&
          console.info('📩 Message recceived in SW:', event?.data ?? 'undefined message');
        event?.data?.data && ShowPushNotification(event?.data?.data);
      });
    })
    .catch((error) => {
      isDev && console.error('Error while registring Service Worker', error);
    });
} else {
  console.error('Service Workers are not supported in this browser.');
}

async function requestPermissions() {
  if (typeof window !== 'undefined' && typeof Notification !== 'undefined') {
    let permission = 'granted';

    if (Notification.permission === 'default') {
      permission = await Notification.requestPermission();
    }

    if (permission === 'denied') {
      isDev && console.warn('Permission refused for notifications.');
    }

    return permission === 'granted';
  } else {
    isDev && console.warn('Notifications not supported in this browser.');
  }
}

async function getSession() {
  try {
    const result = await apolloClient.query({ query: GetSessionDocument });
    return Boolean(result?.data?.session);
  } catch (error) {
    isDev && console.error('Could not get session:', error);
  }
}

async function subscribeToPushNotifications() {
  try {
    isDev && console.log('🔔 Registering push notifications');

    const isSession = await getSession();

    if (!isSession) {
      // Push notifications for now only for logged users
      isDev && console.warn('User not logged, cannot subscribe to push notifications.');
      return;
    }

    const isPermission = await requestPermissions();

    if (!isPermission) {
      isDev && console.warn('Permission refused for notifications.');
      return;
    }

    const registration = await navigator.serviceWorker.ready;

    let subscription = await registration.pushManager.getSubscription();
    isDev && console.log(subscription ? '🔔 Has subscription' : '🔕 Has no subscription');

    if (!subscription) {
      const vapidKey = import.meta.env.VITE_VAPID_PUBLIC_KEY;

      if (vapidKey) {
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidKey,
        });
        isDev && console.log(subscription ? '🔔 Got subscription' : '🔕 Could not get subscription');
      } else {
        isDev && console.warn('No public VAPID key found.');
        return;
      }
    }

    if (subscription && subscription.endpoint && subscription !== undefined) {
      try {
        await apolloClient.mutate({
          mutation: SavePushSubscriptionDocument,
          variables: {
            input: {
              endpoint: subscription.endpoint,
              p256dh: subscription.toJSON().keys?.p256dh ?? '',
              auth: subscription.toJSON().keys?.auth || '',
            },
          },
        });
      } catch (error) {
        isDev && console.error('🔕 Could save subscription:', error);
      }
    }
  } catch (error) {
    isDev && console.error('Could not subscribe to push notifications:', error);
  }
}

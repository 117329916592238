import moment, { Moment } from 'moment';

import { GetEventQuery } from '@graphql';

interface InitialEvent {
  id?: GetEventQuery['event']['id'];
  title: GetEventQuery['event']['title'];
  description?: GetEventQuery['event']['description'] | null;
  from?: GetEventQuery['event']['from'];
  to?: GetEventQuery['event']['to'];
  covers: GetEventQuery['event']['covers'];
  category?: GetEventQuery['event']['category'];
}

const getMinimalFromDate = (from?: Moment | Date | null) => {
  const minimalFrom = moment().set({
    hour: moment().add(1, 'hour').hour(),
    minute: 0,
    seconds: 0,
    milliseconds: 0,
  });
  return !from ? minimalFrom : moment(from).isBefore() ? minimalFrom : moment(from);
};

export default function initValues(event?: InitialEvent | null) {
  const from = getMinimalFromDate();
  const to = moment(from).add(1, 'hour');

  return {
    id: event?.id ?? undefined,
    title: event?.title ?? '',
    description: event?.description ?? null,
    from: event?.from ? moment(event?.from) : from,
    to: event?.to ? moment(event?.to) : to,
    cover: event?.covers?.[0]?.url ?? null,
    categoryId: event?.category?.id ?? '',
  };
}

import * as React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import { BookingOccasionEnum } from '@graphql';

interface ConfirmDialogProps {
  handleConfirm: () => void;
  disabled: boolean;
  startTime: Moment;
  participants: number;
  occasion: BookingOccasionEnum | null;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const { handleConfirm, disabled, startTime, participants, occasion } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation('cta');

  const handleClickConfirm = () => {
    setOpen(false);
    handleConfirm();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Spacing = () => <Box sx={{ height: 50 }} />;

  return (
    <>
      <Button disabled={disabled} color="success" variant="contained" fullWidth onClick={handleOpen}>
        {t('save')}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle>{t('confirmDialog.title.Confirm edit', { ns: 'booking' })}</DialogTitle>
        <Spacing />
        <DialogContent>
          <Stack spacing={2} width="100%" divider={<Divider />}>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography variant="body2">{t('dataGrid.Date', { ns: 'booking' })}</Typography>
              <Typography variant="body2">{moment(startTime).format('dddd LL')}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography variant="body2">{t('dataGrid.Hour', { ns: 'booking' })}</Typography>
              <Typography variant="body2">{moment(startTime).format('hh:mm')}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography variant="body2">{t('dataGrid.Participants', { ns: 'booking' })}</Typography>
              <Typography variant="body2">{participants}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography variant="body2">{t('dataGrid.Occasion', { ns: 'booking' })}</Typography>
              <Typography variant="body2">
                {occasion ? t(`occasion.${occasion}`, { ns: 'booking' }) : t(`word.null`, { ns: 'common' })}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <Spacing />
        <DialogActions>
          <Button variant="contained" color="uncolored" onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button variant="contained" color="uncolored" onClick={handleClickConfirm}>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import * as React from 'react';
import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import * as Device from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { useGetAccountQuery } from '@graphql';

interface SupportAccountOverviewProps {}

export function SupportAccountOverview(props: SupportAccountOverviewProps) {
  const { slug } = useParams();
  const theme = useTheme();

  const { data, loading } = useGetAccountQuery({
    skip: !slug,
    variables: { input: { slug: slug! } },
  });

  if (Device.isMobile) {
    return null;
  }

  if (loading) {
    return (
      <Stack p={2} justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    );
  }

  if (!slug) {
    return (
      <Stack p={2} justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body2" fontStyle="italic">
          Aucun compte sélectionné
        </Typography>
      </Stack>
    );
  }

  if (!data?.account) {
    return (
      <Stack p={2} justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body2" fontStyle="italic">
          Compte introuvable
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack p={1} spacing={1} sx={{ backgroundColor: theme.palette.background.paper }} height="100%">
      <Stack
        direction="row"
        p={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Typography variant="body2">ID</Typography>
        <Typography variant="body2" color="textSecondary">
          {data.account.id}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        p={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Typography variant="body2">Type</Typography>
        <Typography variant="body2" color="textSecondary">
          {data.account.__typename}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        p={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <Typography variant="body2">User</Typography>
        <Typography variant="body2" color="textSecondary">
          {data.account.user.firstname + ' ' + data.account.user.lastname.toUpperCase()}
        </Typography>
      </Stack>

      <Stack>{data.account.__typename === 'Spot' && <SpotLinks />}</Stack>
    </Stack>
  );
}

const SpotLinks = () => {
  const theme = useTheme();
  const { slug, tab } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation('field');

  const handleClick = (tab: string) => {
    navigate(`/support/accounts/${slug}/${tab}${search}`, { preventScrollReset: true, replace: true });
  };

  React.useEffect(() => {
    if (!tab) {
      navigate(`/support/accounts/${slug}/identity${search}`, { preventScrollReset: true, replace: true });
    }
  }, []);

  return (
    <Stack spacing={1} direction="row" width="100%">
      <Stack spacing={1} flex={1}>
        {['identity', 'type', 'configuration', 'atmosphere', 'equipment', 'service'].map((link) => (
          <Button
            key={link}
            variant="contained"
            endIcon={<theme.icons.chevron />}
            color="uncolored"
            onClick={() => handleClick(link)}
            disabled={link === tab}
          >
            {t(`spot${link.charAt(0).toUpperCase() + link.slice(1)}.label`)}
          </Button>
        ))}
      </Stack>
      <Stack spacing={1} flex={1}>
        {['suitable', 'payment', 'bringable', 'highlight', 'schedules'].map((link) => (
          <Button
            key={link}
            variant="contained"
            endIcon={<theme.icons.chevron />}
            color="uncolored"
            onClick={() => handleClick(link)}
            disabled={link === tab}
          >
            {t(`spot${link.charAt(0).toUpperCase() + link.slice(1)}.label`)}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

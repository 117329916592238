import * as React from 'react';
import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import { Account, useGetSpotDetailedQuery, Spot } from '@graphql';
import { AccountStatuses, AccountDescription, NameDisplay, NameSlug, RemoveAvatar } from '../common';
import { SpotConfiguration } from './configuration';
import { SpotAtmosphere } from './Atmosphere';
import { SpotBringable } from './Bringable';
import { SpotEquipment } from './Equipment';
import { SpotHighlight } from './Highlight';
import { SpotPayment } from './Payment';
import { SpotService } from './Service';
import { SpotSuitable } from './Suitable';
import { SpotSchedules } from './Schedules';
import { SpotType } from './Type';

interface SearchAccountDisplaySpot {}

export function SearchAccountDisplaySpot(props: SearchAccountDisplaySpot) {
  const theme = useTheme();
  const { slug, tab } = useParams();

  const { data, loading } = useGetSpotDetailedQuery({
    skip: !slug,
    variables: { slug: slug! },
    fetchPolicy: 'cache-first',
  });

  if (!slug || !data) {
    return null;
  }

  if (loading) {
    return (
      <Stack p={2} alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  const { spot } = data;

  if (!spot) {
    return (
      <Stack p={2} justifyContent="center" alignItems="center" height="100%">
        <Typography variant="body2" fontStyle="italic">
          Aucun spot trouvé
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack spaicing={2}>
      {tab === 'identity' && (
        <Stack width="100%" spacing={5} divider={<Divider />}>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            divider={
              <Divider flexItem variant="vertical" sx={{ backgroundColor: theme.palette.grey[600] }} />
            }
          >
            <Stack mx={10} pt={2} spacing={2} justifyContent="center" alignItems="center">
              <Avatar src={spot.avatar?.url} sx={{ width: 100, height: 100 }} />
              <RemoveAvatar account={spot as Account} imageId={spot.avatar?.id} />
            </Stack>
            <AccountStatuses account={spot as Spot} />
          </Stack>
          <NameDisplay id={spot.id} name={spot.name} />
          <NameSlug id={spot.id} slug={spot.slug} />
          <AccountDescription id={spot.id} description={spot.description} />
        </Stack>
      )}
      {tab === 'configuration' && <SpotConfiguration account={spot as Spot} />}
      {tab === 'atmosphere' && <SpotAtmosphere account={spot as Spot} />}
      {tab === 'equipment' && <SpotEquipment account={spot as Spot} />}
      {tab === 'service' && <SpotService account={spot as Spot} />}
      {tab === 'suitable' && <SpotSuitable account={spot as Spot} />}
      {tab === 'payment' && <SpotPayment account={spot as Spot} />}
      {tab === 'bringable' && <SpotBringable account={spot as Spot} />}
      {tab === 'type' && <SpotType account={spot as Spot} />}
      {tab === 'highlight' && <SpotHighlight account={spot as Spot} />}
      {tab === 'schedules' && <SpotSchedules account={spot as Spot} />}
    </Stack>
  );

  //   <Stack spaicing={2}>
  //     <TabContext value={tab}>
  //       <Box
  //         sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: theme.palette.background.default }}
  //       >
  //         <Tabs
  //           value={tab}
  //           variant="scrollable"
  //           scrollButtons="auto"
  //           textColor="inherit"
  //           indicatorColor="primary"
  //           onChange={handleChange}
  //         >
  //           <Tab label="identité" value="1" />
  //           <Tab label="configuration" value="2" />
  //           <Tab label="ambiance" value="3" />
  //           <Tab label="equipements" value="4" />
  //           <Tab label="services" value="5" />
  //           <Tab label="occasions" value="6" />
  //           <Tab label="paiements" value="7" />
  //           <Tab label="apportables" value="8" />
  //           <Tab label="types" value="9" />
  //           <Tab label="horaires" value="10" />
  //         </Tabs>
  //       </Box>
  //       <TabPanel value="1" sx={{ px: 0, py: 2 }}>
  //         {/* IDENTITY */}
  //         <Stack
  //           width="100%"
  //           spacing={5}
  //           divider={<Divider />}
  //         >
  //           <Stack pt={2} spacing={2} justifyContent="center" alignItems="center">
  //             <Avatar src={spot.avatar?.url} sx={{ width: 100, height: 100 }} />
  //             <RemoveAvatar account={spot as Account} imageId={spot.avatar?.id} />
  //           </Stack>
  //           <NameDisplay id={spot.id} name={spot.name} />
  //           <NameSlug id={spot.id} slug={slug} />
  //           <SpotTypes account={spot as Spot} />
  //           <AccountStatuses account={spot as Spot} />
  //         </Stack>
  //       </TabPanel>
  //       <TabPanel value="2" sx={{ px: 0, py: 2 }}>
  //         <SpotConfiguration account={spot as Spot} />
  //       </TabPanel>
  //       <TabPanel value="3" sx={{ px: 0, py: 2 }}>
  //         <SpotAtmosphere account={spot as Spot} />
  //       </TabPanel>
  //       <TabPanel value="4" sx={{ px: 0, py: 2 }}>
  //         <SpotEquipment account={spot as Spot} />
  //       </TabPanel>
  //       <TabPanel value="5" sx={{ px: 0, py: 2 }}>
  //         <SpotService account={spot as Spot} />
  //       </TabPanel>
  //       <TabPanel value="6" sx={{ px: 0, py: 2 }}>
  //         <SpotSuitable account={spot as Spot} />
  //       </TabPanel>
  //       <TabPanel value="7" sx={{ px: 0, py: 2 }}>
  //         <SpotPayment account={spot as Spot} />
  //       </TabPanel>
  //       <TabPanel value="8" sx={{ px: 0, py: 2 }}>
  //         <SpotBringable account={spot as Spot} />
  //       </TabPanel>
  //       <TabPanel value="9">
  //         <SpotType account={spot as Spot} />
  //       </TabPanel>
  //       <TabPanel value="10">
  //         <SpotSchedules account={spot as Spot} />
  //       </TabPanel>
  //     </TabContext>
  //   </Stack>
  // );
}

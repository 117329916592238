import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Device from 'react-device-detect';

import { GetSpotStatsQuery } from '@graphql';
import CountLineChart from './CountLineChart';

const SPACING = 2;

interface CountSlotProps {
  period: 'day' | 'week' | 'month' | 'quarter' | 'year' | 'always';
  stats: GetSpotStatsQuery['getSpotStats'] | null;
}

export function CountSlot(props: CountSlotProps) {
  const theme = useTheme();

  if (Device.isMobileOnly) {
    return (
      <Stack
        p={SPACING}
        spacing={SPACING}
        width="100%"
        sx={{ backgroundColor: theme.palette.background.paper }}
      >
        <Typography fontWeight="bold">Comptes</Typography>
        <CountLineChart {...props} />
      </Stack>
    );
  }

  if (Device.isDesktop) {
    return (
      <Stack
        p={SPACING}
        spacing={SPACING}
        width="100%"
        sx={{ backgroundColor: theme.palette.background.paper }}
      >
        <Typography fontWeight="bold">Comptes</Typography>
        <CountLineChart {...props} />
      </Stack>
    );
  }

  return (
    <Stack width="100%">
      <CountLineChart {...props} />
    </Stack>
  );
}

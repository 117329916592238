import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { TimeValidationError } from '@mui/x-date-pickers/models';

import {
  useBookBySpotMutation,
  BookingOccasionEnum,
  BookingStatusEnum,
  useSpotSchedulesQuery,
} from '@graphql';
import { refetchBookingsVar } from '@context';
import { shouldDisableBookingDate, shouldDisableBookingTime, initBookingDateTime } from '@utils';
import { useSession } from '@hooks';
import validationSchema from './validationSchema';

interface CreateBookingFormProps {
  handleClose: () => void;
  spotSlug: string;
  isOpen: boolean;
  isDisplayCalendar: boolean;
}

export default function CreateBookingForm(props: Readonly<CreateBookingFormProps>) {
  const { handleClose, isDisplayCalendar } = props;
  const [isDateClosed, setIsDateClosed] = React.useState(false);
  const [timeError, setTimeError] = React.useState<TimeValidationError | null>(null);
  const { account } = useSession();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('booking');

  const { data } = useSpotSchedulesQuery({
    skip: !account?.slug,
    variables: { slug: account?.slug } as { slug: string },
  });

  const [createBooking] = useBookBySpotMutation({
    onCompleted() {
      refetchBookingsVar(true);
      toast.success(t('update.success'));
    },
    onError() {
      toast.error(t('update.error'));
    },
  });

  const getInitalValues = () => {
    const focusDate = searchParams.get('date') ?? undefined;
    const startTime = moment(focusDate).add(1, 'hour').set({ minute: 0, second: 0, millisecond: 0 });

    const defaultValues = {
      startDate: moment(startTime).startOf('day'),
      startTime: startTime,
      participants: '2',
      occasion: '',
      status: BookingStatusEnum.Accepted,
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
    };

    if (account?.__typename === 'Spot') {
      const schedules = { opening: account.opening, closing: account.closing };
      const initialStartTime = initBookingDateTime(schedules, moment(focusDate).startOf('day'));

      if (initialStartTime.isValid()) {
        defaultValues.startTime = initialStartTime;
        defaultValues.startDate = moment(initialStartTime).startOf('day');
      }
    }

    return defaultValues;
  };

  const formik = useFormik({
    initialValues: getInitalValues(),
    validationSchema: validationSchema(null, t, account?.__typename),
    validateOnMount: false,
    onSubmit: async (values) => {
      const booking = {
        client: {
          firstname: values.firstname,
          lastname: values.lastname.length ? values.lastname : undefined,
          email: values.email.length ? values.email : undefined,
          phone: values.phone,
        },
        date: values.startDate.format('YYYY-MM-DD'),
        time: values.startTime.format('HH:mm:ss'),
        startTime: values.startTime.toDate(),
        participants: +values.participants,
        occasion: values.occasion.length ? (values.occasion as BookingOccasionEnum) : undefined,
        status: values.status,
      };

      await createBooking({ variables: { input: booking } });

      handleClose();
    },
  });

  React.useEffect(() => {
    const isClosed = data?.spotSchedules
      ? shouldDisableBookingDate(
          formik.values.startDate,
          data?.spotSchedules.opening,
          data?.spotSchedules.closing
        )
      : true;

    setIsDateClosed(isClosed);
  }, [formik.values.startDate]);

  React.useEffect(() => {
    if (isDateClosed) {
      setTimeError(null);
      formik.resetForm();
    }
  }, [isDateClosed]);

  const errorMessage = React.useMemo(() => {
    if (isDateClosed) {
      return '';
    } else if (timeError === 'shouldDisableTime-hours') {
      return 'Le spot est fermé à cette heure là';
    }
    return '';
  }, [timeError]);

  if (account?.__typename !== 'Spot') {
    return null;
  }

  return (
    <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Stack
        spacing={2}
        mb={3}
        width="100%"
        height="100%"
        justifyContent="space-between"
        divider={<Divider />}
      >
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <Typography variant="subtitle1" align="center">
            {t('add a booking', { ns: 'cta' }).charAt(0).toUpperCase() +
              t('add a booking', { ns: 'cta' }).slice(1)}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            fontWeight="bold"
            color={isDateClosed ? 'warning.main' : 'text.primary'}
          >
            {moment(formik.values.startDate).format('LLLL').split(' ').slice(0, -1).join(' ')}
          </Typography>
        </Stack>
        {isDisplayCalendar && (
          <FormControl fullWidth error={!!formik.errors.startDate}>
            <DateCalendar
              disablePast
              timezone="Europe/Paris"
              value={formik.values.startDate}
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue('startDate', value.startOf('day'));

                  const startTime = moment(formik.values.startTime).set({
                    year: value.year(),
                    month: value.month(),
                    date: value.date(),
                  });

                  formik.setFieldValue('startTime', startTime);
                }
              }}
              shouldDisableDate={(date) => {
                return data?.spotSchedules
                  ? shouldDisableBookingDate(date, data?.spotSchedules.opening, data?.spotSchedules.closing)
                  : false;
              }}
              sx={{ width: '100%' }}
            />
            {isDateClosed && (
              <Alert severity="warning" icon={false}>
                {t('alert.spot is closed this day', { ns: 'booking', spot: data?.spotSchedules.name })}
              </Alert>
            )}
          </FormControl>
        )}
        <DesktopTimePicker
          name="startTime"
          label="Heure d'arrivée"
          disablePast
          closeOnSelect
          timezone="Europe/Paris"
          value={formik.values.startTime}
          ampm={false}
          onError={(newError) => setTimeError(newError)}
          skipDisabled
          disabled={isDateClosed}
          timeSteps={{ hours: 1, minutes: 15, seconds: 60 }}
          slotProps={{
            textField: {
              helperText: errorMessage,
              error: !isDateClosed && !!timeError,
            },
          }}
          onChange={(value) => {
            if (value) {
              const dateTime = moment(value).set({
                year: formik.values.startDate.year(),
                month: formik.values.startDate.month(),
                date: formik.values.startDate.date(),
              });

              formik.setFieldValue('startTime', dateTime);
            }
          }}
          shouldDisableTime={(date) => {
            if (!data?.spotSchedules || !formik.values.startDate) {
              return false;
            }

            const dateTime = moment(date).set({
              year: formik.values.startDate.year(),
              month: formik.values.startDate.month(),
              date: formik.values.startDate.date(),
            });

            return shouldDisableBookingTime(
              dateTime,
              data?.spotSchedules.opening,
              data?.spotSchedules.closing
            );
          }}
        />
        <TextField
          {...formik.getFieldProps('phone')}
          required
          fullWidth
          variant="outlined"
          slotProps={{ input: { min: 1 } }}
          name="phone"
          type="tel"
          label={t('mobilePhone.label', { ns: 'field' })}
          onChange={formik.handleChange}
          disabled={isDateClosed}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone ? String(formik.errors.phone) : null}
        />
        <TextField
          {...formik.getFieldProps('firstname')}
          required
          fullWidth
          variant="outlined"
          slotProps={{ input: { min: 1 } }}
          name="firstname"
          label={t('firstname.label', { ns: 'field' })}
          onChange={formik.handleChange}
          disabled={isDateClosed}
          error={formik.touched.firstname && Boolean(formik.errors.firstname)}
          helperText={
            formik.touched.firstname && formik.errors.firstname ? String(formik.errors.firstname) : null
          }
        />
        <TextField
          {...formik.getFieldProps('lastname')}
          fullWidth
          variant="outlined"
          slotProps={{ input: { min: 1 } }}
          name="lastname"
          label={t('lastname.label', { ns: 'field' })}
          onChange={formik.handleChange}
          disabled={isDateClosed}
          error={formik.touched.lastname && Boolean(formik.errors.lastname)}
          helperText={
            formik.touched.lastname && formik.errors.lastname ? String(formik.errors.lastname) : null
          }
        />
        <TextField
          {...formik.getFieldProps('email')}
          fullWidth
          variant="outlined"
          slotProps={{ input: { min: 1 } }}
          name="email"
          label={t('email.label', { ns: 'field' })}
          onChange={formik.handleChange}
          disabled={isDateClosed}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email ? String(formik.errors.email) : null}
        />
        <TextField
          {...formik.getFieldProps('participants')}
          required
          fullWidth
          variant="outlined"
          slotProps={{ input: { min: 1 } }}
          type="number"
          id="book-participants"
          name="participants"
          label={t('participants.label', { ns: 'field' })}
          disabled={isDateClosed}
          onChange={(e) => {
            const value = Math.max(1, parseInt(e.target.value, 10));
            formik.setFieldValue('participants', isNaN(value) ? '' : value);
          }}
          error={formik.touched.participants && Boolean(formik.errors.participants)}
          helperText={
            formik.touched.participants && formik.errors.participants
              ? String(formik.errors.participants)
              : null
          }
        />
        <FormControl fullWidth>
          <InputLabel id="status-select-label" disabled={isDateClosed}>
            Status
          </InputLabel>
          <Select
            autoFocus
            labelId="status-select-label"
            id="status-select"
            value={formik.values.status}
            name="status"
            label="Status"
            disabled={isDateClosed}
            onChange={formik.handleChange}
          >
            {[BookingStatusEnum.Accepted, BookingStatusEnum.Pending].map((status) => (
              <MenuItem key={status} value={status}>
                {t(`status.${status}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <Select
            {...formik.getFieldProps('occasion')}
            autoFocus
            labelId="occasion-select-label"
            name="occasion"
            autoComplete="off"
            disabled={isDateClosed}
            displayEmpty
          >
            <MenuItem autoFocus value="">
              <em>{t('occasion select.placeholder', { ns: 'field' })}</em>
            </MenuItem>
            {Object.entries(BookingOccasionEnum).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {t(`suitable.${key.charAt(0).toLowerCase() + key.slice(1)}`, {
                  ns: 'spot',
                })}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText id="occasion-helper-text">{t('word.Optional', { ns: 'common' })}</FormHelperText>
        </FormControl>
        <Stack spacing={3} direction="row">
          <Button variant="outlined" color="uncolored" onClick={handleClose} fullWidth>
            {t('cancel', { ns: 'cta' })}
          </Button>
          <Button
            disabled={!formik.dirty || !formik.isValid}
            variant="contained"
            color="success"
            fullWidth
            type="submit"
          >
            {t('save', { ns: 'cta' })}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

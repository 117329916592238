import * as React from 'react';
import { useReactiveVar } from '@apollo/client';

import { GetSessionQuery, useGetSessionQuery } from '@graphql';
import { loginStatusVar } from '@context';

export default function useSession() {
  const loginStatus = useReactiveVar(loginStatusVar);

  const { data, refetch } = useGetSessionQuery({
    onCompleted({ session }) {
      // update isLoggedIn, necessary when app is initialized
      if (loginStatus.isLoggedIn && session === null) {
        loginStatusVar({
          ...loginStatus,
          isLoggedIn: false,
        });
      }
      // force login if session is not null
      if (!loginStatus.isLoggedIn && session) {
        const { ssoProvider, birthdate, firstname, lastname, gender } = session;
        const mustFinalizeRegister = !!ssoProvider && (!birthdate || !firstname || !lastname || !gender);

        loginStatusVar({
          ...loginStatus,
          mustFinalizeRegister,
          isLoggedIn: true,
        });
      }
    },
    onError(error) {
      // @ts-ignore
      const message = error?.networkError?.result?.errors?.[0]?.message;
      if (message === 'UNAUTHENTICATED') {
        loginStatusVar({
          ...loginStatus,
          isLoggedIn: false,
          shouldLogFirst: true,
        });
      } else if (message === 'SESSION_EXPIRED') {
        loginStatusVar({
          ...loginStatus,
          isLoggedIn: false,
          isSessionExpired: true,
        });
      }
    },
    fetchPolicy: 'cache-first',
  });

  React.useEffect(() => {
    if (loginStatus.forceGetSession) {
      refetch().then(() => {
        loginStatusVar({
          ...loginStatus,
          forceGetSession: false,
        });
      });
    }
  }, [loginStatus]);

  const getSelectedAccount = (session: GetSessionQuery['session']) => {
    if (!session?.accounts) return null;

    const selectedAccount = session.accounts.find(({ isCurrent }) => isCurrent);

    return selectedAccount || null;
  };

  return React.useMemo(
    () => ({
      isLoggedIn: loginStatus.isLoggedIn,
      user: data?.session ?? null,
      account: getSelectedAccount(data?.session),
      refetch,
    }),
    [data, loginStatus.isLoggedIn]
  );
}

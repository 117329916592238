import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  GetConsumerDetailedQuery,
  useListFriendsQuery,
  useSearchFriendsLazyQuery,
  ListFriendsQuery,
  SearchFriendsQuery,
} from '@graphql';
import { useSession } from '@hooks';
import { truncateText } from '@utils';
import { EmptyState } from '../../common';

interface FriendsProps {
  consumer: GetConsumerDetailedQuery['consumer'];
}

function Friends(props: FriendsProps) {
  const { consumer } = props;
  const { account } = useSession();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSearching, setIsSearching] = React.useState(false);
  const [friends, setFriends] = React.useState<ListFriendsQuery['listFriends']['friends']>([]);
  const [pendingFriends, setPendingFriends] = React.useState<ListFriendsQuery['listFriends']['friends']>([]);
  const [searchResult, setSearchResult] = React.useState<SearchFriendsQuery['searchFriends']>([]);
  const { t } = useTranslation('cta');
  const isMe = consumer.id === account?.id;

  const [searchFriends, { loading: searchLoading }] = useSearchFriendsLazyQuery({
    onCompleted: ({ searchFriends }) => {
      setSearchResult(searchFriends);
    },
  });
  const { loading } = useListFriendsQuery({
    skip: !consumer?.id,
    variables: { accountId: consumer.id },
    onCompleted: ({ listFriends }) => {
      setFriends(listFriends.friends.filter((f) => f.isAccepted));
      if (isMe) {
        setPendingFriends(listFriends.friends.filter((f) => f.isPending && !f.isSent));
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: yup.object({
      text: yup.string(),
    }),
    onSubmit: (values) => {
      searchFriends({ variables: { text: values.text } });
    },
  });

  React.useEffect(() => {
    setIsSearching(formik.values.text.length > 0);

    if (formik.values.text.length > 0) {
      handleSearch(formik.values.text);
    }
  }, [formik.values.text]);

  // do no spam the API
  const handleSearch = React.useCallback(
    _.debounce((searchValue: string) => {
      formik.submitForm();
    }, 300),
    []
  );

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const slug = (e.currentTarget as HTMLElement).dataset.slug;
    setSearchParams({ c: slug || '' }, { preventScrollReset: true });
  };

  return (
    <Stack spacing={5} p={3}>
      {isMe && (
        <TextField
          name="text"
          fullWidth
          size="small"
          placeholder={t('search', { ns: 'cta' })}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.text && Boolean(formik.errors.text)}
          helperText={formik.touched.text && Boolean(formik.errors.text) ? String(formik.errors.text) : null}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <theme.icons.search sx={{ color: theme.palette.text.secondary }} />
                </InputAdornment>
              ),
            },
          }}
        />
      )}
      {isMe && !isSearching && pendingFriends.length > 0 && (
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography>{t('text.New requests', { ns: 'account' })}</Typography>
          </Stack>
          <Stack spacing={2} divider={<Divider />}>
            {pendingFriends.map(({ friend }) => (
              <Link
                key={friend.id}
                to={`?type=consumer&q=${friend.slug}`}
                data-slug={friend.slug}
                style={{ textDecoration: 'none', width: '100%' }}
                onClick={handleClick}
              >
                <Stack direction="row" spacing={1} alignItems="center" width="100%">
                  <Avatar src={friend.avatar?.url} alt={friend.name} sx={{ width: 50, height: 50 }} />
                  <Stack width="100%">
                    <Typography fontWeight="bold" fontSize={{ xs: '.8rem', sm: '.7rem' }}>
                      {truncateText(friend.name, 25)}
                    </Typography>
                    <Typography color="textSecondary" fontSize={{ xs: '.8rem', sm: '.7rem' }}>
                      @{truncateText(friend.slug, 25)}
                    </Typography>
                  </Stack>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <IconButton size="small">
                      <theme.icons.close />
                    </IconButton>
                    <IconButton size="small" sx={{ backgroundColor: 'green' }}>
                      <theme.icons.check sx={{ color: 'white' }} />
                    </IconButton>
                  </Stack>
                </Stack>
              </Link>
            ))}
          </Stack>
        </Stack>
      )}
      {
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Typography>{t(isSearching ? 'word.Results' : 'word.Friends', { ns: 'common' })}</Typography>
          </Stack>
          {(!isSearching && friends.length === 0) || (isSearching && searchResult.length === 0) ? (
            <EmptyState
              text={
                isSearching
                  ? t('word.No result', { ns: 'common' })
                  : t('text.No friends yet, use the search above to find them', { ns: 'account' })
              }
            />
          ) : null}
          {(isSearching ? searchResult : friends).map(({ friend }) => (
            <Link
              key={friend.id}
              to={`?type=consumer&q=${friend.slug}`}
              data-slug={friend.slug}
              style={{ textDecoration: 'none' }}
              onClick={handleClick}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Avatar src={friend.avatar?.url} alt={friend.name} sx={{ width: 50, height: 50 }} />
                <Stack>
                  <Typography fontWeight="bold" fontSize={{ xs: '.8rem', sm: '.7rem' }}>
                    {truncateText(friend.name, 25)}
                  </Typography>
                  <Typography color="textSecondary" fontSize={{ xs: '.8rem', sm: '.7rem' }}>
                    @{truncateText(friend.slug, 25)}
                  </Typography>
                </Stack>
              </Stack>
            </Link>
          ))}
        </Stack>
      }
      {(searchLoading || loading) && [...Array(5)].map((_, idx) => <FriendSkeleton key={idx} />)}
    </Stack>
  );
}

const FriendSkeleton = () => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Skeleton variant="circular" width={50} height={50} />
    <Stack>
      <Skeleton variant="text" width={250} />
      <Skeleton variant="text" width={150} />
    </Stack>
  </Stack>
);

export default React.memo(Friends);

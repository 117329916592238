import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import { useReactiveVar } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import * as Device from 'react-device-detect';

import { createBookingVar } from '@context';
import { useSession } from '@hooks';
import CreateBookingForm from './booking-forms/CreateBookingForm';

interface BookingCreateDialogProps {
  Trigger?: React.ElementType;
  slug?: string;
}

export function BookingCreateDialog(props: Readonly<BookingCreateDialogProps>) {
  const { Trigger, slug } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const dialogContentRef = React.useRef<HTMLDivElement>(null);
  const isCreating = useReactiveVar(createBookingVar);
  const [searchParams, setSearchParams] = useSearchParams();
  const { account } = useSession();
  const targetSlug = slug ?? account?.slug;

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    createBookingVar(false);

    // remove calendar from search params
    searchParams.delete('calendar');
    setSearchParams(searchParams);

    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    setIsOpen(isCreating);
  }, [isCreating]);

  if (!targetSlug) {
    return null;
  }

  return (
    <>
      {Trigger && (
        <Box onClick={handleOpen} p={0} m={0}>
          <Trigger />
        </Box>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullScreen={Device.isMobileOnly}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <Stack ref={dialogContentRef} spacing={1} p={3} height="100%">
          <CreateBookingForm
            handleClose={handleClose}
            spotSlug={targetSlug}
            isOpen={isOpen}
            isDisplayCalendar={!!searchParams.get('calendar')}
          />
        </Stack>
      </Dialog>
    </>
  );
}

import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { useGetConversationsQuery } from '@graphql';
import { AvatarCustom } from '@components';
import { truncateText, getConversationTitle } from '@utils';
import { TFunction } from 'i18next';

interface ConversationDrawerProps {
  handleOpenDialog: (event: React.MouseEvent<HTMLElement>) => void;
}

export function ConversationDrawer(props: Readonly<ConversationDrawerProps>) {
  const { handleOpenDialog } = props;
  const theme = useTheme();
  const { account } = useSession();
  const { t } = useTranslation('conversation');

  if (!account) {
    return null;
  }

  const { data } = useGetConversationsQuery({
    skip: !account,
  });

  return (
    <Box
      sx={{
        width: 300,
        color: theme.palette.text.secondary,
        '& .MuiListItemButton-root': {
          color: theme.palette.text.secondary,
          '& .MuiListItemIcon-root, & .MuiListItemText-root': {
            color: theme.palette.text.secondary,
          },
          '&:hover': {
            color: theme.palette.text.primary,
            '& .MuiListItemIcon-root, & .MuiListItemText-root': {
              color: theme.palette.text.primary,
            },
          },
        },
        '& .MuiDivider-root': {
          backgroundColor: theme.palette.grey[700],
        },
      }}
      role="presentation"
    >
      <List
        subheader={
          <ListSubheader component="div" id="messages-subheader">
            <Typography variant="h6" fontWeight="bold" mt={2}>
              {t('Messages')}
            </Typography>
          </ListSubheader>
        }
      >
        <Stack spacing={1}>
          {data?.conversations.length ? (
            data?.conversations
              .filter((conversation) => conversation.lastMessage)
              .map(
                (conversation) =>
                  conversation.lastMessage && (
                    <Stack
                      key={conversation.id}
                      spacing={1}
                      onClick={handleOpenDialog}
                      data-id="message"
                      conversation-id={conversation.id}
                    >
                      <ListItem disablePadding>
                        <ListItemButton width="100%">
                          <Stack direction="row" spacing={1} alignItems="center" width="100%">
                            <Badge
                              color="error"
                              badgeContent={conversation.unreadCount}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              {conversation.members.length > 2 ? (
                                <theme.icons.people
                                  sx={{
                                    width: 40,
                                    height: 40,
                                  }}
                                />
                              ) : (
                                <AvatarCustom
                                  ressource={conversation.lastMessage.sender.avatar?.url || null}
                                  id={conversation.id}
                                  type="account"
                                  sx={{
                                    width: 40,
                                    height: 40,
                                  }}
                                />
                              )}
                            </Badge>
                            <Stack width="100%">
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                                color={conversation.unreadCount > 0 ? 'text.primary' : 'text.secondary'}
                              >
                                {getConversationTitle(account.id, conversation)}
                              </Typography>
                              <Stack direction="row" spacing={0.5}>
                                <Typography variant="subtitle2">
                                  {formatLastMessage(
                                    t,
                                    conversation.lastMessage.sender.name,
                                    conversation.lastMessage.content,
                                    conversation.lastMessage.sender.id === account.id
                                  )}
                                </Typography>
                              </Stack>
                              <Typography variant="caption">
                                {moment(conversation.lastMessage.createdAt).fromNow()}
                              </Typography>
                            </Stack>
                          </Stack>
                        </ListItemButton>
                      </ListItem>
                      <Divider sx={{ backgroundColor: theme.palette.grey[800] }} variant="middle" flexItem />
                    </Stack>
                  )
              )
          ) : (
            <Stack>
              <ListItem disablePadding>
                <ListItemButton width="100%" disabled>
                  <Stack direction="row" spacing={1} alignItems="center" width="100%">
                    <Typography variant="body2" fontWeight="bold">
                      {t('No message')}
                    </Typography>
                  </Stack>
                </ListItemButton>
              </ListItem>
              <Divider sx={{ backgroundColor: theme.palette.grey[800] }} variant="middle" flexItem />
            </Stack>
          )}
        </Stack>
      </List>
    </Box>
  );
}

function formatLastMessage(t: TFunction, sender: string, message: string, isMe = true) {
  const senderText = isMe ? t('me') : truncateText(sender, 10);
  return (
    <>
      <span style={{ fontWeight: isMe ? 'normal' : '500' }}>{senderText}</span>
      {` : ${truncateText(message, 50)}`}
    </>
  );
}

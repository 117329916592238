import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EventStatusEnum, ListMyEventsQuery } from '@graphql';

interface BookingSlotProps {
  event: ListMyEventsQuery['myEvents'][0];
}

function EventSlot(props: BookingSlotProps) {
  const { event } = props;
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation('event');

  const statusColor = {
    [EventStatusEnum.Draft]: 'uncolored',
    [EventStatusEnum.Published]: 'success',
    [EventStatusEnum.Postponed]: 'warning',
    [EventStatusEnum.Canceled]: 'error',
    [EventStatusEnum.Deleted]: 'error',
    [EventStatusEnum.Removed]: 'error',
  };

  const handleClick = () => {
    const search = searchParams.size ? `?${searchParams.toString()}` : '';
    navigate(`/me/calendar/event/${event.id}/edit`, {
      state: {
        from: `${pathname}${search}`,
      },
    });
  };

  return (
    <Stack
      onClick={handleClick}
      spacing={2}
      p={2}
      justifyContent="space-between"
      divider={<Divider />}
      flex={1}
      sx={{
        borderRadius: theme.borderRadius,
        backgroundColor: theme.palette.grey[900],
      }}
    >
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2" fontWeight="bolder">
            {event.title}
          </Typography>
        </Stack>
        <Chip color={statusColor[event.status]} label={t(`status.${event.status}`)} size="small" />
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <Typography variant="body2">
          {t('word.Hour', { ns: 'common' })} {moment(event.from).format('hh:mm')}
        </Typography>
        <Typography variant="body2">{event.likers.count} like</Typography>
      </Stack>
    </Stack>
  );
}

export default React.memo(EventSlot);

import { ApolloClient, InMemoryCache, from, split, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLJSON } from 'graphql-type-json';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { loginStatusVar } from '../context';
import typePolicies from './typePolicies';

const cache = new InMemoryCache(typePolicies);

(async () => {
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    trigger: 'write',
    serialize: true,
    debug: import.meta.env.DEV,
    key: 'festayre-cache',
    maxSize: 5000000,
  });
})();

const uploadLink = createUploadLink({
  uri: import.meta.env.VITE_API_URI,
  useGETForQueries: false,
  headers: {
    'apollo-require-preflight': 'true',
  },
  credentials: 'include',
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_WS_URI,
    retryAttempts: 5,
    shouldRetry: (errorOrCloseEvent: unknown) => {
      console.error('GraphQL WebSocket error:', errorOrCloseEvent);
      const event = errorOrCloseEvent as { code?: number };
      return event.code !== 1006; // Prevent reconnection if WebSocket was force-closed
    },
    keepAlive: 10000,
    retryWait: (retries: number) => {
      // increase the wait time for each retry max 30s
      const delay = Math.min(1000 * Math.pow(2, retries), 30000);
      return new Promise((resolve) => setTimeout(resolve, delay));
    },
    on: {
      connecting: () => import.meta.env.DEV && console.log('WebSocket connecting'),
      connected: () => import.meta.env.DEV && console.log('WebSocket connected'),
      closed: (event) => import.meta.env.DEV && console.log('WebSocket closed', event),
      error: (err: any) => import.meta.env.DEV && console.log('WebSocket error', err),
    },
  })
);

// @ts-ignore
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) {
    import.meta.env.DEV && console.error(`[networkError]: ${networkError}`);

    return forward(operation).map((response) => {
      if (response.errors) {
        import.meta.env.DEV && console.error(`[Network errors]: ${response.errors}`);
      }
      return response;
    });
  }

  if (graphQLErrors) {
    graphQLErrors.map(
      ({ message, locations, path }) =>
        import.meta.env.DEV &&
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

    const authError = graphQLErrors.filter((err) => ['UNAUTHENTICATED'].includes(err.message)).length > 0;
    const refreshError =
      graphQLErrors.filter((err) => ['INVALID_REFRESH_TOKEN', 'REFRESH_TOKEN_EXPIRED'].includes(err.message))
        .length > 0;
    const sessionError =
      graphQLErrors.filter((err) => ['INVALID_TOKEN', 'SESSION_EXPIRED'].includes(err.message)).length > 0;

    if (authError || refreshError || sessionError) {
      import.meta.env.DEV && sessionError && console.error('SESSION_EXPIRED or INVALID_TOKEN, force logout');
      // When the refreshToken is expired or invalid, force logout
      loginStatusVar({
        isLoggedIn: false,
        isLogging: false,
        forceGetSession: true,
        mustFinalizeRegister: false,
        isSessionExpired: true,
        shouldLogFirst: false,
        redirection: null,
      });

      // Reinitialize Apollo cache
      cache.reset();

      // Stop the operation
      return new Observable((observer) => observer.complete());
    }
  }

  return forward(operation);
});

// Utiliser split pour diriger les requêtes en fonction de leur type.
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  from([errorLink, uploadLink])
);

const client = new ApolloClient({
  name: 'Festayre',
  version: '1.0.0',
  credentials: 'include',
  resolvers: {
    JSON: GraphQLJSON,
  },
  cache,
  link: splitLink,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;

import * as React from 'react';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { useSwitchAccountMutation } from '@graphql';

type KeyEvent = React.KeyboardEvent<HTMLDivElement> & {
  defaultMuiPrevented?: boolean;
};

interface Option {
  id: string;
  name: string;
  avatarUrl: string | null;
}

export default function AccountChange() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<Option[]>([]);
  const { user, account, refetch } = useSession();
  const theme = useTheme();
  const { t } = useTranslation('common');

  const [switchAccount] = useSwitchAccountMutation({
    async onCompleted() {
      await refetch();
      setLoading(false);
    },
  });

  React.useEffect(() => {
    if (!account || !user || user.accounts.length <= 1) {
      return;
    }

    const formattedOptions = user.accounts
      .filter((acc) => !acc.deletedAt)
      .map((acc) => ({
        id: acc.id,
        name: acc.name,
        avatarUrl: acc.avatar?.url ?? null,
      }));
    setOptions(formattedOptions);
  }, [user]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyEvent = (event: KeyEvent) => {
    if (event.key === 'Escape') {
      // Prevent's default 'Enter' behavior.
      event.defaultMuiPrevented = true;
      handleClose();
    }
  };

  const filterOptions = createFilterOptions<Option>({
    matchFrom: 'any',
    stringify: (option) => option.name,
    limit: 10,
    trim: true,
    ignoreAccents: true,
    ignoreCase: true,
  });

  if (!account || !user || user.accounts.length <= 1) {
    return null;
  }

  return (
    <ListItem disablePadding={!open}>
      {loading ? (
        <LinearProgress sx={{ width: '100%' }} />
      ) : open ? (
        <Autocomplete
          id="account-select"
          autoHighlight
          fullWidth
          disableClearable
          disableCloseOnSelect
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          multiple={false}
          options={options}
          noOptionsText={t('word.No result')}
          value={{ id: account.id, name: account.name, avatarUrl: account.avatar?.url ?? null }}
          getOptionDisabled={(option) => option.id === account.id}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          filterOptions={filterOptions}
          onKeyDown={handleKeyEvent}
          onChange={(event, newValue) => {
            setLoading(true);
            handleClose();
            if (newValue) {
              switchAccount({
                variables: {
                  id: newValue.id,
                },
              });
            }
          }}
          renderOption={(props, option, { inputValue }) => {
            const { key, ...optionProps } = props;
            const matches = match(option.name, inputValue, { insideWords: true });
            const parts = parse(option.name, matches);

            return (
              <Box
                {...props}
                key={optionProps.id || key}
                component="li"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Stack spacing={1} flex={1} direction="row" justifyContent="flex-start" alignItems="center">
                  <Avatar src={option.avatarUrl} sx={{ width: 30, height: 30 }}>
                    {option.name[0]}
                  </Avatar>
                  <Box>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </Box>
                </Stack>
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              slotProps={{
                input: {
                  style: {
                    width: '100%',
                  },
                  ...params.InputProps,
                  autoComplete: 'off',
                  type: 'search',
                },
              }}
            />
          )}
          sx={{
            '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
              backgroundColor: '#363636',
            },
            "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']": {
              backgroundColor: '#4396e6',
            },
            "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused": {
              backgroundColor: '#3878b4',
            },
          }}
        />
      ) : (
        <ListItemButton onClick={handleOpen}>
          <ListItemIcon>
            <theme.icons.change />
          </ListItemIcon>
          <ListItemText primary={'Changer de compte'} />
        </ListItemButton>
      )}
    </ListItem>
  );
}

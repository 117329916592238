import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { useFormik } from 'formik';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams, useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill-new';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as Device from 'react-device-detect';

import 'react-quill-new/dist/quill.snow.css';
import './quill.css';

import { useSession } from '@hooks';
import { ImageUpload } from '@components';
import { previewEventVar, refetchEventsVar } from '@context';
import { initBookingDateTime } from '@utils';
import {
  useCreateEventMutation,
  useUpdateEventMutation,
  useGetEventLazyQuery,
  useListEventCategoriesQuery,
} from '@graphql';
import initValues from './initValues';
import validationSchema from './validationSchema';

interface EventFormDialogProps {
  variant: 'create' | 'edit';
}

export function EventFormDialog(props: Readonly<EventFormDialogProps>) {
  const { variant } = props;
  const { account } = useSession();
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchParams] = useSearchParams();
  const { eventId } = useParams();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('field');

  const initialDescriptionContent = '<p><br></p>';
  const from = moment(searchParams.get('date') ?? moment())
    .hour(0)
    .minute(0)
    .second(0)
    .add(1, 'hour');
  const to = moment(from).add(1, 'hour');

  const defaultValues = {
    id: undefined,
    title: '',
    description: initialDescriptionContent,
    from: from,
    to: to,
    covers: [],
    categoryId: '',
  };

  const { data } = useListEventCategoriesQuery();

  const [getEvent, { data: eventData }] = useGetEventLazyQuery({
    onCompleted({ event }) {
      setIsOpen(true);
      formik.resetForm({
        values: {
          ...initValues(event),
          cover: event.covers?.[0]?.url || null,
        },
      });
    },
  });

  const [updateEvent, { loading: updating }] = useUpdateEventMutation({
    onCompleted: ({ updateEvent }) => {
      refetchEventsVar(true);
      handleClose();
      toast(t('event.toast.update.success', { ns: 'field' }), { type: 'success' });
    },
    onError(error) {
      console.log(`[{error}]:`, error);
      toast.error(t('event.toast.update.error', { ns: 'field' }));
    },
  });

  const [createEvent, { loading: creating }] = useCreateEventMutation({
    onCompleted: ({ createEvent }) => {
      refetchEventsVar(true);
      handleClose();
      toast(t('event.toast.create.success', { ns: 'field' }), { type: 'success' });
    },
    onError(error) {
      toast.error(t('event.toast.create.error', { ns: 'field' }));
    },
  });

  const formik = useFormik({
    initialValues: initValues(defaultValues),
    validationSchema: validationSchema(t),
    onSubmit: async ({ id, cover, ...values }) => {
      // Sanitize description
      const sanitizedDescription = DOMPurify.sanitize(values.description || '');
      Object.assign(values, {
        description: sanitizedDescription.length ? sanitizedDescription : null,
      });

      if (variant === 'create') {
        await createEvent({
          variables: {
            input: { ...values, cover: cover || undefined },
          },
        });
      } else if (eventData) {
        let coverToUpdate;
        const [existginCover = null] = eventData.event.covers;
        if (cover === null && existginCover !== null) {
          // remove old cover
          coverToUpdate = null;
        } else if (existginCover !== cover) {
          // update old cover
          coverToUpdate = cover;
        }

        console.log('[CHECK]:', {
          ...values,
          id: eventId as string,
          cover: coverToUpdate,
        });

        await updateEvent({
          variables: {
            input: {
              ...values,
              id: eventId as string,
              cover: coverToUpdate,
            },
          },
        });
      }
    },
  });

  React.useEffect(() => {
    if (!isOpen) {
      formik.resetForm({ values: initValues(defaultValues) });
    } else if (variant === 'create') {
      const initialValues = { ...defaultValues };

      if (account?.__typename === 'Spot') {
        const schedules = { opening: account.opening, closing: account.closing };
        const focusDate = searchParams.get('date') || undefined;
        const isToday = moment(focusDate).isSame(moment(), 'day');
        const dateInit =
          isToday && focusDate
            ? moment(focusDate)
                .set({
                  hour: moment().hour(),
                  minute: moment().minute(),
                  second: moment().second(),
                  millisecond: moment().millisecond(),
                })
                .add(1, 'hour')
            : moment(focusDate);

        const initialStartTime = initBookingDateTime(schedules, dateInit);
        initialValues.from = initialStartTime;
        initialValues.to = moment(initialStartTime).add(3, 'hours');
      } else if (searchParams.get('date')) {
        const focusDate = moment(searchParams.get('date')).hour(0).minute(0).second(0).add(1, 'hour');
        initialValues.from = moment(focusDate);
        initialValues.to = moment(initialValues.from).clone().add(1, 'hour');
      }

      formik.resetForm({ values: initValues(initialValues) });
    }
  }, [isOpen, searchParams]);

  React.useEffect(() => {
    if (variant === 'create') {
      handleOpen();
    } else if (variant === 'edit' && eventId) {
      getEvent({ variables: { id: eventId } });
    }
  }, [pathname, eventId]);

  React.useEffect(() => {
    // seek for the h3 button
    const h3Button = document.querySelector('.ql-picker-item[data-value="3"]');

    // Verify if the element exists before modifying it
    if (h3Button) {
      h3Button.setAttribute('data-label', 'Titre'); // Modifier l'étiquette
      h3Button.innerHTML = '';
    }
  }, []);

  const handleOpen = () => {
    // dispatch({ type: 'SET_EVENT_FORM', payload: null });
    setIsOpen(true);
  };

  const handleSubmit = () => {
    formik.submitForm();
  };

  const handleClose = () => {
    setIsOpen(false);
    formik.resetForm({ values: initValues(defaultValues) });

    const previousPath = state?.from || '/me/calendar/event';
    navigate(previousPath, { preventScrollReset: true, replace: true });
  };

  const handleImageSelected = (files: File[]) => {
    formik.setFieldValue('images', files);
  };

  if (!data?.eventCategories?.length) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      fullScreen={Device.isMobileOnly}
    >
      <Stack height="100%">
        <DialogTitle id="event-form-dialog-title">
          {t(`event.title.${eventData?.event.id ? 'edit' : 'create'}`)}
        </DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Stack spacing={2}>
              <TextField
                required
                label={t('event.label.title')}
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title ? String(formik.errors.title) : null}
                fullWidth
              />
              <Divider />
              <ImageUpload
                label="Ajouter une couverture"
                onImageProcessed={(file) => formik.setFieldValue('cover', file)}
                onImageSelected={handleImageSelected}
                initialPreview={formik.values.cover}
              />
              <Divider />
              <DateTimePicker
                label={t('event.label.from') + ' *'}
                value={formik.values.from}
                onChange={(value) => {
                  formik.setFieldValue('from', value);
                  formik.setFieldTouched('from', !moment(value).isSame(formik.values.from));
                }}
                disablePast
                slotProps={{
                  textField: {
                    name: 'from',
                    error: formik.touched.from && Boolean(formik.errors.from),
                    helperText:
                      formik.touched.from && formik.errors.from ? String(formik.errors.from) : undefined,
                    fullWidth: true,
                  },
                }}
              />
              <DateTimePicker
                label={t('event.label.to') + ' *'}
                value={formik.values.to}
                onChange={(value) => {
                  formik.setFieldValue('to', value);
                  formik.setFieldTouched('to', !moment(value).isSame(formik.values.to));
                }}
                disablePast
                slotProps={{
                  textField: {
                    name: 'to',
                    error: formik.touched.to && Boolean(formik.errors.to),
                    helperText: formik.touched.to && formik.errors.to ? String(formik.errors.to) : undefined,
                    fullWidth: true,
                  },
                }}
              />
              <Divider />
              <FormControl fullWidth error={formik.touched.description && Boolean(formik.errors.description)}>
                <ReactQuill
                  theme="snow"
                  value={formik.values.description || ''}
                  onChange={(content) => {
                    formik.setFieldValue('description', content);
                    formik.setFieldTouched('description', content !== formik.values.description);
                  }}
                  placeholder="Description de l'évènement..."
                  modules={{
                    toolbar: [
                      [{ header: [false, 3] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['clean'],
                    ],
                  }}
                />
                <FormHelperText>
                  {formik.touched.description && formik.errors.description
                    ? String(formik.errors.description)
                    : undefined}
                </FormHelperText>
              </FormControl>
              <Divider />
              <FormControl
                required
                fullWidth
                error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
              >
                <InputLabel id="category-select-label">{t(`event.label.category`)}</InputLabel>
                <Select
                  required
                  labelId="category-select-label"
                  id="category-select"
                  value={formik.values.categoryId || ''}
                  name="categoryId"
                  label="Category"
                  onChange={formik.handleChange}
                >
                  {[
                    { id: '', name: t(`event.placeholder.category`) },
                    ...data.eventCategories.map((category) => ({
                      id: category.id,
                      name: t(`category.${category.name}`, { ns: 'event' }),
                    })),
                  ].map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.errors.categoryId ? String(formik.errors.categoryId) : undefined}
                </FormHelperText>
              </FormControl>
            </Stack>
          </DialogContent>
        </form>
        <DialogActions>
          <Stack direction="row" spacing={2} p={3} width="100%" justifyContent="space-between">
            <Button onClick={handleClose} color="uncolored" variant="outlined" fullWidth={Device.isMobile}>
              {t('cancel', { ns: 'cta' })}
            </Button>
            <Button
              loading={creating || updating}
              fullWidth={Device.isMobile}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              color="success"
              disabled={!(formik.isValid && formik.dirty)}
            >
              {t('save', { ns: 'cta' })}
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as Device from 'react-device-detect';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Skeleton from '@mui/material/Skeleton';

import { truncateText } from '@utils';
export interface SlideProps {
  fullWidth?: boolean;
  id: string;
  type: 'Spot' | 'Event';
  defaultImage?: string | null;
  covers: { id: string; url: string }[];
  title: string;
  subtitleLeft: string;
  subtitleRight: string;
  textBottomLeft: string;
  textBottomRight: string;
  subTextBottomLeft: string;
  SubBottomLeft?: React.ReactNode;
  SubBottomRight?: React.ReactNode;
  subTextBottomRight: string;
  to: string;
}

export interface MobileSlideProps {
  type: 'Spot' | 'Event';
  id: string;
  to: string;
  defaultImage?: string | null;
  covers: { id: string; url: string }[];
  title: string;
  subtitleLeft: string;
  subtitleRight: string;
}

// a function that returns a random consistant cover
function getCover(covers: { id: string; url: string }[]) {
  const date = new Date();
  const hours = date.getHours();
  return covers[hours % covers.length];
}

const transition = {
  transitionDuration: '.3s',
  transitionTimingFunction: 'ease-in-out',
};

export function MobileSlide(props: MobileSlideProps) {
  const { type, to, title = '', subtitleLeft = '', subtitleRight = '', covers = [], defaultImage } = props;
  const theme = useTheme();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "400px",  // trigger when 800px from the bottom (early load)
  });

  return (
    <div ref={ref}>
      {inView ? (
        <Link to={to} preventScrollReset={true} state={{ from: 'slide' }} style={{ textDecoration: 'none' }}>
          <Stack
            alignItems="center"
            width="100%"
            height="auto"
            sx={{
              overflow: 'hidden',
              borderRadius: theme.spacing(0.5),
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}
          >
            <MobileCover {...props} />
            <Stack
              className="infos"
              width="100%"
              spacing={0}
              justifyContent="center"
              alignItems="space-between"
              p={1}
            >
              <Typography component="span" fontSize={{ xs: '.7rem', sm: '.8rem' }} fontWeight="bold">
                {truncateText(title, 25)}
              </Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography component="span" variant="body2" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
                  {truncateText(subtitleLeft, 15)}
                </Typography>
                <Typography component="span" variant="body2" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
                  {truncateText(subtitleRight, 15)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Link>
      ) : (
        <SlideSkeleton />
      )}
    </div>
  );
}

export function Slide(props: SlideProps) {
  const {
    type,
    to,
    title = '',
    subtitleLeft = '',
    subtitleRight = '',
    covers = [],
    defaultImage,
    textBottomLeft,
    textBottomRight,
    subTextBottomLeft,
    SubBottomLeft,
    SubBottomRight,
    subTextBottomRight,
    fullWidth = false,
  } = props;
  const theme = useTheme();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: "800px", // trigger when 800px from the bottom (early load)
  });

  return (
    <div ref={ref}>
      {inView ? (
        <Link to={to} preventScrollReset={true} state={{ from: 'slide' }} style={{ textDecoration: 'none' }}>
          <Stack
            alignItems="center"
            width={fullWidth ? '100%' : 'auto'}
            height={fullWidth ? {} : { xs: '200px', sm: '300px', md: '350px' }}
            sx={Object.assign(
              {
                overflow: 'hidden',
                aspectRatio: '4/3',
                borderRadius: theme.spacing(0.5),
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              },
              Device.isDesktop && {
                ...transition,
                '&:hover': {
                  cursor: 'pointer',
                  ...transition,
                  '.img': {
                    aspectRatio: '23/9',
                    ...transition,
                  },
                  '.infos': {
                    flexGrow: 2,
                    ...transition,
                  },
                  '.extras': {
                    mt: 3.5,
                    opacity: 1,
                    flexGrow: 1,
                    ...transition,
                  },
                },
              }
            )}
          >
            <Cover {...props} />
            <Stack
              className="infos"
              width="100%"
              spacing={Device.isDesktop ? 1 : 0}
              justifyContent="center"
              alignItems="space-between"
              py={{ sx: 1, sm: 3, md: 3 }}
              px={Device.isDesktop ? 2 : 1}
              sx={{
                flexGrow: 1,
                ...transition,
              }}
            >
              <Typography component="span" fontSize={{ xs: '.7rem', sm: '.8rem' }} fontWeight="bold">
                {truncateText(title, 50)}
              </Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography component="span" variant="body2" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
                  {truncateText(subtitleLeft, Device.isMobileOnly ? 25 : undefined)}
                </Typography>
                <Typography component="span" variant="body2" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
                  {truncateText(subtitleRight, Device.isMobileOnly ? 25 : undefined)}
                </Typography>
              </Stack>
              <Stack
                className="extras"
                divider={<Divider sx={{ backgroundColor: theme.palette.grey[700] }} />}
                spacing={1}
                sx={{
                  height: 0,
                  opacity: 0,
                  ...transition,
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" fontSize=".8rem">
                    {truncateText(textBottomLeft, 35)}
                  </Typography>
                  <Typography variant="body2" fontSize=".7rem">
                    {textBottomRight}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {SubBottomLeft ? (
                    SubBottomLeft
                  ) : (
                    <Typography variant="body2" fontSize=".8rem">
                      {truncateText(subTextBottomLeft, 35)}
                    </Typography>
                  )}
                  {SubBottomRight ? (
                    SubBottomRight
                  ) : (
                    <Typography variant="body2" fontSize=".7rem">
                      {truncateText(subTextBottomRight, 35)}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Link>
      ) : (
        <SlideSkeleton />
      )}
    </div>
  );
}

const SlideSkeleton = () => (
  <Stack
    height={{ xs: '150px', sm: '200px', md: '250px', lg: '300px', xl: '350px' }}
    sx={{ aspectRatio: '16/9', flex: 1 }}
  >
    <Skeleton variant="rounded" width="100%" height="100%" />
  </Stack>
);

const Cover = (props: SlideProps) => {
  const { covers = [], defaultImage, type } = props;
  const theme = useTheme();

  if (covers.length > 0) {
    return (
      <Box
        className="img"
        component="img"
        src={getCover(covers).url}
        alt={'name' + ' cover'}
        width="100%"
        sx={{
          aspectRatio: '16/9',
          objectFit: 'cover',
          objectPosition: 'center',
          borderRadius: theme.spacing(0.5, 0.5, 0, 0),
          ...transition,
        }}
      />
    );
  } else if (type === 'Spot' && defaultImage) {
    return (
      <Box
        className="img"
        component="img"
        src={'/spot/' + defaultImage}
        alt="Default cover"
        width="100%"
        sx={{
          aspectRatio: '16/9',
          objectFit: 'cover',
          objectPosition: 'center',
          filter: 'grayscale(100%)',
          opacity: 0.5,
          borderRadius: theme.spacing(0.5, 0.5, 0, 0),
          ...transition,
        }}
      />
    );
  } else {
    return (
      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        sx={{
          aspectRatio: '16/9',
          backgroundColor: '#000',
          borderRadius: theme.spacing(0.5, 0.5, 0, 0),
          ...transition,
        }}
      >
        <theme.icons.noImage />
      </Stack>
    );
  }
};

const MobileCover = (props: MobileSlideProps) => {
  const { covers = [], defaultImage, type } = props;
  const theme = useTheme();

  if (covers.length > 0) {
    return (
      <Box
        className="img"
        component="img"
        src={getCover(covers).url}
        alt={'name' + ' cover'}
        width="100%"
        sx={{
          aspectRatio: '16/9',
          objectFit: 'cover',
          objectPosition: 'center',
          borderRadius: theme.spacing(0.5, 0.5, 0, 0),
        }}
      />
    );
  } else if (type === 'Spot' && defaultImage) {
    return (
      <Box
        className="img"
        component="img"
        src={'/spot/' + defaultImage}
        alt="Default cover"
        width="100%"
        sx={{
          aspectRatio: '16/9',
          objectFit: 'cover',
          objectPosition: 'center',
          filter: 'grayscale(100%)',
          opacity: 0.5,
          borderRadius: theme.spacing(0.5, 0.5, 0, 0),
        }}
      />
    );
  } else {
    return (
      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        sx={{
          aspectRatio: '16/9',
          backgroundColor: '#000',
          borderRadius: theme.spacing(0.5, 0.5, 0, 0),
        }}
      >
        <theme.icons.noImage />
      </Stack>
    );
  }
};
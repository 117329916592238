import { useTheme, alpha, lighten } from '@mui/material';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';

import { ADDICTS_COLOR, BOOKINGS_COLOR, EVENTS_COLOR } from '../constants';

interface CardProps {
  type: 'addicts' | 'bookings' | 'events' | 'transactions' | 'views' | 'visits';
  status?: 'active' | 'disabled';
  value?: {
    current?: number | null;
    previous?: number | null;
    __typename?: string;
  };
}

export function Card(props: CardProps) {
  const { type, status = 'active', value } = props;
  const theme = useTheme();

  const title = {
    addicts: 'ADDICTS',
    bookings: 'RESERVATIONS',
    events: 'EVENEMENTS',
    transactions: 'TRANSACTIONS',
    views: 'VUES',
    visits: 'VISITES',
  };

  const icon = {
    addicts: (
      <theme.icons.favorite fontSize="small" sx={{ color: status === 'active' ? ADDICTS_COLOR : 'gray' }} />
    ),
    bookings: (
      <theme.icons.booking fontSize="small" sx={{ color: status === 'active' ? BOOKINGS_COLOR : 'gray' }} />
    ),
    events: (
      <theme.icons.event fontSize="small" sx={{ color: status === 'active' ? EVENTS_COLOR : 'gray' }} />
    ),
    transactions: (
      <theme.icons.timeline
        fontSize="small"
        sx={{ color: status === 'active' ? theme.palette.secondary.main : 'gray' }}
      />
    ),
    views: (
      <theme.icons.see
        fontSize="small"
        sx={{ color: status === 'active' ? theme.palette.primary.light : 'gray' }}
      />
    ),
    visits: (
      <theme.icons.people
        fontSize="small"
        sx={{ color: status === 'active' ? theme.palette.success.main : 'gray' }}
      />
    ),
  };

  const info = {
    addicts: 'Nombre de personnes qui se sont abonnés à ton spot',
    bookings: 'Nombre de réservations effectuées sur la période',
    events: "Nombre d'événements de ton spot qui ont eu lieu sur a période",
    transactions: 'Nombre de transactions effectuées sur votre spot.',
    views:
      "Nombre de fois que ton spot a été vu (page de profil, résultats de recherche, page d'accueil, etc.)",
    visits: 'Nombre de visiteurs uniques qui a consulté le profil de ton spot',
  };

  const progressValue = getProgressValue(value);
  const progressColor = getProgressColor(progressValue);
  const progress = getProgress(progressValue);

  return (
    <Stack
      p={2}
      flex={1}
      justifyContent="center"
      width="100%"
      sx={{
        backgroundColor:
          status === 'disabled'
            ? lighten(theme.palette.background.default, 0.01)
            : theme.palette.background.paper,
        cursor: 'default',
        aspectRatio: '1/1',
        border: status === 'disabled' ? `1px solid ${alpha(theme.palette.background.paper, 0.8)}` : undefined,
      }}
    >
      {status === 'active' && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center" spacing={1}>
              {icon[type]}
              <Typography fontSize={{ xs: '.5rem', sm: '.6rem', md: '.7rem', lg: '.8rem' }} fontWeight="bold">
                {title[type]}
              </Typography>
            </Stack>
            <Tooltip title={info[type]} placement="bottom">
              <theme.icons.info color="uncolored" fontSize="small" />
            </Tooltip>
          </Stack>
          <Stack spacing={1} marginY="auto">
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="flex-start">
              <Typography fontSize={{ xs: '1rem', sm: '1.25rem', md: '1.5rem', lg: '2rem' }}>
                {value ? value.current : 0}
              </Typography>
              <Chip
                label={progress}
                color={progressColor}
                variant="outlined"
                size="small"
                sx={{ width: 'fit-content' }}
              />
            </Stack>
          </Stack>
          <Stack alignItems="center">
            <Typography
              variant="caption"
              color="textSecondary"
              fontSize={{ xs: '.4rem', sm: '.5rem', md: '.6rem' }}
            >
              Période précédente: {value?.previous ?? 0}
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
}

function getProgressValue(value: CardProps['value']) {
  if (value?.current == null || value?.previous == null) {
    return null;
  }

  if (value.previous === 0) {
    return value.current > 0 ? value.current * 100 : 0;
  }

  return ((value.current - value.previous) / value.previous) * 100;
}

function getProgressColor(value: number | null) {
  switch (value) {
    case null:
    case 0:
      return 'uncolored';
    default:
      return value > 0 ? 'success' : 'error';
  }
}

function getProgress(value: number | null) {
  switch (value) {
    case null:
      return '';
    case 0:
      return '=';
    default:
      return `${value > 0 ? '+' : ''}${_.round(value, 1)}%`;
  }
}

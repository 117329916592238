import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useReactiveVar } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import * as Device from 'react-device-detect';

import {
  useGetSpotBookingLazyQuery,
  useGetConsumerBookingLazyQuery,
  GetSpotBookingQuery,
  GetConsumerBookingQuery,
} from '@graphql';
import { useSession, useToastError } from '@hooks';
import { previewBookingVar } from '@context';
import BookingHistory from './BookingHistory';
import BookingSpot from './BookingSpot';
import BookingConsumer from './BookingConsumer';
import EditConsumerForm from './booking-forms/EditConsumerForm';
import EditSpotForm from './booking-forms/EditSpotForm';

interface BookingDialogProps {
  Trigger?: React.ElementType;
}

export function BookingDialog(props: Readonly<BookingDialogProps>) {
  const { Trigger } = props;
  const [open, setOpen] = React.useState(false);
  const { account } = useSession();
  const { t } = useTranslation('cta');
  const params = useParams();
  const navigate = useNavigate();
  const toastError = useToastError();
  const bookingId = useReactiveVar(previewBookingVar);

  const [getConsumerBooking, { data: consumer, loading: consumerLoading }] = useGetConsumerBookingLazyQuery({
    onError() {
      toastError({ message: t('toast.error.Unable to found the booking', { ns: 'booking' }) });
      handleClose();
    },
  });

  const [getSpotBooking, { data: spot, loading: spotLoading }] = useGetSpotBookingLazyQuery({
    onError() {
      toastError({ message: t('toast.error.Unable to found the booking', { ns: 'booking' }) });
      handleClose();
    },
  });

  React.useEffect(() => {
    if (bookingId) {
      handleOpen();
      if (account?.__typename === 'Consumer') {
        getConsumerBooking({ variables: { id: bookingId } });
      } else if (account?.__typename === 'Spot') {
        getSpotBooking({ variables: { id: bookingId } });
      }
    }
  }, [bookingId]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    previewBookingVar(null);
    if (params.bookingId) {
      // navigate back if the dialog was opened from a direct link
      // @ts-expect-error - expect "to" to be a string
      navigate(-1, { preventScrollReset: true });
    }
    setOpen(false);
  };

  return (
    <>
      {Trigger && (
        <Box onClick={handleOpen} p={0} m={0}>
          <Trigger />
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={Device.isMobileOnly}
        fullWidth
        maxWidth="sm"
        scroll="body"
        disablePortal
      >
        <Stack spacing={1} p={3} height="100%">
          <BookingDialogContent
            isLoading={consumerLoading || spotLoading}
            consumerBooking={consumer?.booking}
            spotBooking={spot?.booking}
            handleClose={handleClose}
          />
        </Stack>
      </Dialog>
    </>
  );
}

interface BookingDialogContentProps {
  isLoading: boolean;
  consumerBooking?: GetConsumerBookingQuery['booking'];
  spotBooking?: GetSpotBookingQuery['booking'];
  handleClose: () => void;
}

function BookingDialogContent(props: Readonly<BookingDialogContentProps>) {
  const { isLoading, consumerBooking, spotBooking, handleClose } = props;
  const [isEdit, setIsEdit] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const { account } = useSession();
  const { t } = useTranslation('cta');
  const theme = useTheme();
  const bookingId = useReactiveVar(previewBookingVar);

  const tabPanelSx = { height: '80vh', px: 0, pt: 3, pb: 1 };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  if (!bookingId) {
    return null;
  }

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="primary" />
      </Stack>
    );
  }

  if (isEdit) {
    if (account?.__typename === 'Consumer' && consumerBooking) {
      return <EditConsumerForm booking={consumerBooking} handleEdit={handleEdit} />;
    } else if (account?.__typename === 'Spot' && spotBooking) {
      return <EditSpotForm booking={spotBooking} handleEdit={handleEdit} />;
    }
  }

  return (
    <TabContext value={value}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <TabList
          onChange={handleChange}
          aria-label="booking tabs"
          textColor="inherit"
          indicatorColor="primary"
        >
          <Tab label={t('title', { ns: 'booking' })} value="1" />
          <Tab label={t('history.title', { ns: 'booking' })} value="2" />
        </TabList>
        <IconButton onClick={handleClose} sx={{ cursor: 'pointer' }}>
          <theme.icons.close sx={{ color: theme.palette.text.secondary }} />
        </IconButton>
      </Stack>
      <TabPanel value="1" sx={tabPanelSx}>
        {consumerBooking && <BookingConsumer booking={consumerBooking} handleEdit={handleEdit} />}
        {spotBooking && <BookingSpot booking={spotBooking} handleEdit={handleEdit} />}
      </TabPanel>
      <TabPanel value="2" sx={tabPanelSx}>
        <BookingHistory
          bookingId={bookingId}
          updates={consumerBooking?.updates || spotBooking?.updates || []}
          useNote={spotBooking?.client.__typename === 'BookingClient'}
        />
      </TabPanel>
    </TabContext>
  );
}

import { useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import moment from 'moment';

import { BookingStatusEnum, GetSpotBookingQuery } from '@graphql';
import { getStatusColor } from '@utils';

interface BookingSpotProps {
  booking: GetSpotBookingQuery['booking'];
  handleEdit: () => void;
}

export default function BookingSpot(props: Readonly<BookingSpotProps>) {
  const { booking, handleEdit } = props;
  const { t } = useTranslation('booking');
  const theme = useTheme();

  const lastConsumerUpdateStatus = booking.updates
    .filter((update) => update.status && update.author.__typename === 'Consumer')
    .pop();

  return (
    <Stack spacing={5} width="100%" height="100%" justifyContent="space-between">
      {lastConsumerUpdateStatus?.status === BookingStatusEnum.Canceled && (
        <Alert severity="warning">{t('canceledByConsumer')}</Alert>
      )}
      <Stack spacing={2} width="100%" divider={<Divider />}>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Creator')}</Typography>
          <Typography variant="body2">{t(`origin.${booking.origin}`)}</Typography>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Client')}</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            {getClientAvatar(booking).length ? (
              <Avatar src={getClientAvatar(booking)} sx={{ width: 30, height: 30 }} />
            ) : null}
            <Typography variant="body2">{getClientName(booking)}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Date')}</Typography>
          <Typography variant="body2">{moment(booking.startTime).format('dddd LL')}</Typography>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Hour')}</Typography>
          <Typography variant="body2">{getHour(booking)}</Typography>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Status')}</Typography>
          <Chip
            label={t(`status.${booking.status}`, { ns: 'booking' })}
            color={getStatusColor(booking.status)}
          />
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Participants')}</Typography>
          <Typography variant="body2">{booking.participants}</Typography>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Phone')}</Typography>
          <Typography variant="body2">{getClientPhone(booking, t)}</Typography>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Age')}</Typography>
          <Typography variant="body2">{getClientAge(booking, t)}</Typography>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Gender')}</Typography>
          <Typography variant="body2">{getClientGender(booking, t)}</Typography>
        </Stack>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{t('dataGrid.Occasion')}</Typography>
          <Typography variant="body2">
            {booking.occasion ? t(`occasion.${booking.occasion}`) : t(`word.null`, { ns: 'common' })}
          </Typography>
        </Stack>
      </Stack>
      {lastConsumerUpdateStatus?.status !== BookingStatusEnum.Canceled && (
        <Button
          color="uncolored"
          variant={moment(booking.startTime).isBefore() ? 'contained' : 'outlined'}
          disabled={moment(booking.startTime).isBefore()}
          startIcon={<theme.icons.edit />}
          onClick={handleEdit}
        >
          {t('update', { ns: 'cta' })}
        </Button>
      )}
    </Stack>
  );
}

function getHour(booking: GetSpotBookingQuery['booking']) {
  const nearbyTime = moment(booking.startTime).fromNow();
  let date = moment(booking.startTime).format('HH:mm ');

  if (moment().isSame(booking.startTime, 'day')) {
    date += ` (${nearbyTime})`;
  }

  return date;
}

function getClientName(booking: GetSpotBookingQuery['booking']) {
  let clientName = '';

  if (booking.client.__typename === 'BookingClient') {
    clientName = booking.client.firstname;

    if (booking.client.lastname) {
      clientName += ` ${booking.client.lastname}`;
    }
  } else if (booking.client.__typename === 'Consumer') {
    clientName = booking.client.user.firstname + ' ' + booking.client.user.lastname;
  }

  return clientName;
}

function getClientAvatar(booking: GetSpotBookingQuery['booking']) {
  let avatar = '';

  if (booking.client.__typename === 'Consumer') {
    avatar = booking.client.avatar?.url ?? '';
  }

  return avatar;
}

function getClientAge(booking: GetSpotBookingQuery['booking'], t: TFunction) {
  return booking.client.__typename === 'Consumer' && booking.client.user.birthdate
    ? moment(booking.client.user.birthdate).fromNow(true)
    : t(`word.null`, { ns: 'common' });
}

function getClientPhone(booking: GetSpotBookingQuery['booking'], t: TFunction) {
  let phone = '';

  if (booking.client.__typename === 'BookingClient') {
    phone = booking.client.clientPhone;
  } else if (booking.client.__typename === 'Consumer') {
    phone = booking.client.user.mobilePhone ?? '';
  }

  return phone.replace(/(\d{2})(?=\d)/g, '$1 ');
}

function getClientGender(booking: GetSpotBookingQuery['booking'], t: TFunction) {
  return booking.client.__typename === 'Consumer'
    ? t(`gender.${booking.client?.user.gender}`, { ns: 'common' })
    : t(`word.null`, { ns: 'common' });
}

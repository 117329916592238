import * as React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import { DatesSetArg, DateSelectArg, DateSpanApi, DayCellContentArg } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import moment from 'moment';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { createBookingVar } from '@context';
import {
  useListMyBookingsLazyQuery,
  useListMyEventsLazyQuery,
  ListMyBookingsQuery,
  ListMyEventsQuery,
} from '@graphql';
import * as helpers from './helpers';

interface DesktopCalendarProps {
  accountId: string;
  variant: 'all' | 'booking' | 'event';
}

type DateRange = { from: string; to: string } | null;
type ToDisplay = ListMyBookingsQuery['myBookings'] | ListMyEventsQuery['myEvents'];

export function DesktopCalendar(props: Readonly<DesktopCalendarProps>) {
  const { accountId, variant } = props;
  const { user, account } = useSession();
  const [dateRange, setDateRange] = React.useState<DateRange | null>(null);
  const [bookings, setBookings] = React.useState<ListMyBookingsQuery['myBookings']>([]);
  const [events, setEvents] = React.useState<ListMyEventsQuery['myEvents']>([]);
  const [toDisplay, setToDisplay] = React.useState<ToDisplay>([]);
  const navigate = useNavigate();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { t } = useTranslation('cta');

  const [getMyBookings, { loading: bookingsLoading }] = useListMyBookingsLazyQuery({
    onCompleted({ myBookings }) {
      setBookings(myBookings);
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getMyEvents, { loading: eventsLoading }] = useListMyEventsLazyQuery({
    onCompleted({ myEvents }) {
      setEvents(myEvents);
    },
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    const formatedBookings = bookings.map((booking) => ({
      ...booking,
      start: helpers.getItemStart(booking),
      title: helpers.getItemTitle(booking),
      backgroundColor: helpers.getItemColor(booking),
      extendedProps: { ...booking },
    })) as ToDisplay;

    const formatedEvents = events.map((event) => ({
      ...event,
      start: event.from,
      title: event.title,
      // backgroundColor: helpers.getItemColor(event),
      extendedProps: { ...event },
    })) as ToDisplay;

    if (variant === 'all') {
      setToDisplay([...formatedBookings, ...formatedEvents] as ToDisplay);
    } else if (variant === 'booking') {
      setToDisplay(formatedBookings);
    } else if (variant === 'event') {
      setToDisplay(formatedEvents);
    }
  }, [variant, bookings, events]);

  React.useEffect(() => {
    if (dateRange) {
      if (['all', 'booking'].includes(variant) && accountId === account?.id) {
        getMyBookings({ variables: { dateRange } });
      }
      if (['all', 'event'].includes(variant) && accountId === account?.id) {
        getMyEvents({ variables: { dateRange } });
      }
    }
  }, [variant, dateRange]);

  function handleDateSelect(selectInfo: DateSelectArg) {
    const { startStr } = selectInfo;
    setSearchParams({ date: startStr });
    createBookingVar(true);
  }

  function handleDatesSet(dateInfo: DatesSetArg) {
    const { startStr } = dateInfo;
    const from = moment(startStr).startOf('month').toISOString();
    const to = moment(startStr).endOf('month').toISOString();
    setDateRange({ from, to });
  }

  function handleEventClick(clickInfo: any) {
    const { event } = clickInfo;
    const { __typename, id } = event.extendedProps;

    const path = `/me/calendar/${__typename.toLocaleLowerCase()}/${id}`;
    navigate(path, { preventScrollReset: true, state: { from: pathname } });
  }

  function handleCreateBooking() {
    setSearchParams({ date: moment().format('YYYY-MM-DD'), calendar: 'true' });
    createBookingVar(true);
  }

  function handleCreateEvent() {
    setSearchParams({ date: moment().format('YYYY-MM-DD') });
    navigate('/me/calendar/event/create', { state: { from: pathname } });
  }

  function handleSelectAllow(selectInfo: DateSpanApi): boolean {
    const { startStr } = selectInfo;
    const dayIndex = moment(startStr).day();

    if (moment(startStr).endOf('day').isBefore(moment())) {
      return false;
    }

    if (!account) {
      return false;
    }

    if (account.__typename === 'Spot') {
      const { opening, closing } = account;
      const dayOpenging = opening.find((day) => day.dayOfWeek === dayIndex);
      const isClosed = closing.some((range) => moment(startStr).isBetween(range.from, range.to));
      return !!dayOpenging?.openTime && !isClosed;
    }

    return false; // Default disallow selection
  }

  function handleDayCellClassNames(args: DayCellContentArg) {
    const { date } = args;
    const classNames = [];
    const disableClassName = 'fc-day-disabled';
    const dayIndex = moment(date).day();

    if (account && account.__typename === 'Spot') {
      const { opening, closing } = account;
      const dayOpenging = opening.find((day) => day.dayOfWeek === dayIndex);
      const isClosed = closing.some((range) => moment(date).isBetween(range.from, range.to));

      if (!dayOpenging?.openTime || isClosed) {
        classNames.push(disableClassName);
      }
    }

    return classNames;
  }

  const locales = {
    fr: frLocale,
    es: esLocale,
    en: undefined,
  };
  const locale = user?.preferences.language
    ? locales[user?.preferences.language as 'fr' | 'es' | 'en']
    : undefined;

  const addButtonKey = variant === 'booking' ? 'addBookingButton' : 'addEventButton';

  return (
    <FullCalendar
      loading={() => bookingsLoading || eventsLoading}
      height="100%"
      // contentHeight={'200px'} // to remove extra space below days with events
      // dayMaxEvents={1} // nb before cta "+x more"
      themeSystem="bootstrap5"
      firstDay={1} // monday
      locale={locale}
      plugins={[bootstrap5Plugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      // add event button
      customButtons={{
        addBookingButton: {
          text: t('add a booking'),
          click: handleCreateBooking,
        },
        addEventButton: {
          text: t('add an event'),
          click: handleCreateEvent,
        },
      }}
      headerToolbar={{
        left: `prev,next,${addButtonKey}`,
        center: 'title',
        right: 'today',
      }}
      views={{
        dayGridMonth: {
          dayMaxEventRows: 3,
          titleFormat: {
            year: 'numeric',
            month: 'long',
          },
        },
      }}
      events={toDisplay}
      editable={true}
      selectable={true}
      weekends={true}
      showNonCurrentDates={false}
      selectMirror={true}
      select={handleDateSelect}
      eventClick={handleEventClick}
      datesSet={handleDatesSet}
      selectAllow={handleSelectAllow}
      dayCellClassNames={handleDayCellClassNames}
      // update the db
      eventAdd={function () {}}
      eventChange={function () {}}
      eventRemove={function () {}}
    />
  );
}
